import shirtnetwork from '../../lib/shirtnetwork'
const debug = require('debug')('shirtnetwork:store:products')

// initial state
const state = {
  all: [],
  selected: undefined,
  filterMap: [
    /*
    {
      title: 'Schwarz',
      groups: ['Schwarz', 'Black', 'Vintage Black', 'Black / Black', 'Black / Green'],
      color: '#000000',
      picture: ''
    },
    {
      title: 'Rot',
      groups: ['Rot', 'Red', 'White Red', 'Grey Red Grey'],
      color: '#FF0000',
      picture: ''
    },
    {
      title: 'Camouflage',
      groups: ['Camo', 'Camouflage', 'Camouflage Khaki', 'Camouflage Grey', 'Green Camo'],
      color: '#41670c',
      picture: '//api.shirtnetwork.de/out/colorPics/94515/cc17dd62970b0665244edcf69e897534.png'
    },
    {
      title: 'Khaki',
      groups: ['Khaki'],
      color: '#a1a46a',
      picture: ''
    },
    {
      title: 'Olive',
      groups: ['Olive'],
      color: '#484b2d',
      picture: ''
    }
    */
  ],
  filters: [],
  selectedFilters: [],
  maxShowTextLines: 3,
  pagination: {
    count: 0,
    numPages: 0,
    currentPage: 1,
    itemsPerPage: 16
  }
}

// getters
const getters = {
  allProducts: state => [...state.all].sort((a, b) => { return a.ordering > b.ordering }),
  selectedProduct: (state, getters) => getters.templateProduct,
  templateProduct: (state, getters, rootState, rootGetters) => {
    return rootGetters.useTemplate && rootGetters.template ? Object.assign({}, state.selected, {
      price: Number(rootGetters.template.product.price) || state.selected.price,
      name: rootGetters.template.product.title || state.selected.name,
      fixPrice: rootGetters.template.product.fixPrice || state.selected.fixPrice,
      minCount: Number(rootGetters.template.product.minimumAmount) || state.selected.minCount,
      maxCount: Number(rootGetters.template.product.maximumAmount) || state.selected.maxCount,
      packageUnit: Number(rootGetters.template.product.packageUnit) || state.selected.packageUnit
    }) : state.selected
  },
  productPagination: state => state.pagination,
  maxTextLines: (state, getters) => getters.selectedProduct ? (getters.selectedProduct.maxTextLines === -1 ? 99999 : getters.selectedProduct.maxTextLines) : 0,
  showTextLines: (state, getters, rootState, rootGetters) => Math.min(getters.maxTextLines, Math.max(rootGetters.textLines.length + 1, state.maxShowTextLines), 10),
  minProductAmount: (state, getters) => getters.selectedProduct ? Math.max(getters.selectedProduct.minCount, getters.packageUnit) : getters.packageUnit,
  maxProductAmount: (state, getters) => getters.selectedProduct ? (getters.selectedProduct.maxCount <= 0 ? 99999 : getters.selectedProduct.maxCount) : 999999,
  packageUnit: (state, getters) => getters.selectedProduct ? (getters.selectedProduct.packageUnit > 0 ? getters.selectedProduct.packageUnit : 1) : 1,
  productFilters: state => state.filters,
  selectedProductFilters: state => state.selectedFilters,
  filterMap: state => state.filterMap,
  mappedProductFilters: (state, getters) => {
    return getters.filterMap.reduce((res, m) => {
      // Get all filters which apply for at least one variant name in this group
      const variants = getters.productFilters
        .filter((f, i, arr) => arr.indexOf(m) === -1 && m.groups.indexOf(f.name) !== -1)

      // Only push a clone to the result if variants where found, otherwise skip this filter
      variants.length && res.push(Object.assign({}, m, {
        variants: variants
      }))

      return res
    }, [])
  },
  selectedProductFilterVariants: state => state.selectedFilters.reduce((res, f) => {
    return res.concat(f.variants.map(v => v.name))
  }, [])
}

// actions
const actions = {
  async getCategoryProducts ({ getters, dispatch }, category) {
    debug(getters.selectedProductFilterVariants)
    return dispatch('getCategoryProductCount', category).then(() => {
      return dispatch('pageCategoryProducts')
    })
  },
  async pageCategoryProducts ({ rootGetters, state, commit, dispatch }) {
    return rootGetters.user.then(user => {
      return shirtnetwork.getProducts(user, rootGetters.selectedCategory, rootGetters.selectedProductFilterVariants, state.pagination.itemsPerPage, state.pagination.currentPage).then(products => {
        commit('setProducts', products)
        state.selected || dispatch('setFirstProductActive')
      })
    })
  },
  async getCategoryProductCount ({ rootGetters, commit, dispatch }, category) {
    return rootGetters.user.then(user => {
      return shirtnetwork.getProductCount(user, category, rootGetters.selectedProductFilterVariants).then(count => {
        commit('setProductCount', count)
      })
    })
  },
  setProductPage ({ rootState, commit, dispatch }, page) {
    commit('setProductPage', page)
    return dispatch('pageCategoryProducts')
  },
  setFirstProductActive ({ getters, dispatch }) {
    dispatch('setSelectedProduct', getters.allProducts[0])
  },
  async setSelectedProduct ({ rootState, rootGetters, commit, dispatch }, product) {
    return shirtnetwork.getProduct(product).then(async function (product) {
      commit('setSelectedProduct', product)
      commit('setAmount', Math.max(rootGetters.minProductAmount, rootGetters.amount))
      await dispatch('getProductVariants', product)
    })
  },
  async setSelectedProductBySku ({ rootGetters, state, commit, dispatch }, sku) {
    return rootGetters.user.then(user => {
      return shirtnetwork.getProductBySku(user, sku).then(async function (product) {
        commit('setSelectedProduct', product)
        commit('setAmount', Math.max(rootGetters.minProductAmount, rootGetters.amount))
        debug('get variants')
        await dispatch('getProductVariants', product)
        debug('returning from set product')
      })
    })
  },
  async getProductFilter ({ rootGetters, state, commit, dispatch }, category) {
    return rootGetters.user.then(user => {
      return shirtnetwork.getProductFilter(user, category).then(filter => {
        commit('setProductFilters', filter)
      })
    })
  },
  setProductFilters ({ rootState, commit, dispatch }, filters) {
    commit('setProductFilters', filters)
  },
  setSelectedProductFilters ({ rootGetters, commit, dispatch }, filters) {
    commit('setSelectedProductFilters', filters)
    dispatch('getCategoryProducts', rootGetters.selectedCategory)
  },
  setProductFilterMap ({ rootState, commit, dispatch }, filterMap) {
    commit('setProductFilterMap', filterMap)
  },
  setMaxShowTextLines ({ getters, commit }, maxShowTextLines) {
    commit('setMaxShowTextLines', maxShowTextLines)
  },
  setProductsPerPage ({ getters, commit }, productsPerPage) {
    commit('setProductsPerPage', productsPerPage)
  }
}

// mutations
const mutations = {
  setProducts (state, products) {
    state.all = products
  },
  setSelectedProduct (state, product) {
    state.selected = product
  },
  setProductCount (state, count) {
    state.pagination = Object.assign(state.pagination, {
      count: count,
      numPages: Math.ceil(count / state.pagination.itemsPerPage),
      currentPage: 1
    })
  },
  setProductPage (state, page) {
    state.pagination.currentPage = page
  },
  setProductFilters (state, filters) {
    state.filters = filters
  },
  setSelectedProductFilters (state, filters) {
    state.selectedFilters = filters
  },
  setProductFilterMap (state, filterMap) {
    state.filterMap = filterMap
  },
  setMaxShowTextLines (state, maxShowTextLines) {
    state.maxShowTextLines = maxShowTextLines
  },
  setProductsPerPage (state, productsPerPage) {
    state.pagination = Object.assign(state.pagination, { itemsPerPage: productsPerPage })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
