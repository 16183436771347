import shirtnetwork from '../../lib/shirtnetwork'

// initial state
const state = {
  all: [],
  selected: undefined,
  pagination: {
    count: 0,
    numPages: 0,
    currentPage: 1,
    itemsPerPage: 16
  }
}

// getters
const getters = {
  allLogos: state => [...state.all].sort((a, b) => { return a.ordering > b.ordering }),
  selectedLogo: state => state.selected,
  logoPagination: state => state.pagination,
  productLogos: (state, getters, rootState, rootGetters) => rootGetters.selectedProduct && rootGetters.selectedProduct.allowAllLogos === 0 ? rootGetters.selectedProduct.logos.source : undefined,
  allowedLogos: (state, getters, rootState, rootGetters) => {
    const logos = getters.productLogos || getters.allLogos
    console.log(logos)
    return logos.filter(l => rootGetters.logoPrinttypes(l).length > 0)
  },
  isAllowedLogo: (state, getters) => (logo) => !getters.allowedLogos || getters.allowedLogos.length === 0 || getters.allowedLogos.findIndex(allowedLogo => logo.id === allowedLogo.id) !== -1
}

// actions
const actions = {
  getCategoryLogos ({ rootState, state, commit, dispatch }, category) {
    return dispatch('getCategoryLogoCount', category).then(() => {
      return dispatch('pageCategoryLogos')
    })
  },
  pageCategoryLogos ({ rootState, rootGetters, state, commit, dispatch }) {
    return rootGetters.user.then(user => {
      return shirtnetwork.getLogos(user, rootState.logoCategories.selected, state.pagination.itemsPerPage, state.pagination.currentPage).then(logos => {
        commit('setLogos', logos.map(logo => {
          logo.svg = logo.svg || logo.picture
          return logo
        }))
      })
    })
  },
  getCategoryLogoCount ({ rootGetters, commit, dispatch }, category) {
    return rootGetters.user.then(user => {
      return shirtnetwork.getLogoCount(user, category).then(count => {
        commit('setLogoCount', count)
      })
    })
  },
  setLogoPage ({ rootState, commit, dispatch }, page) {
    commit('setLogoPage', page)
    return dispatch('pageCategoryLogos')
  },
  setSelectedLogo ({ rootState, commit, dispatch }, logo) {
    return shirtnetwork.getLogo(logo).then(logo => {
      commit('setSelectedLogo', logo)
      dispatch('addLogo', logo)
      // dispatch('setLogoPrinttypes', logo.printtypes.source)
    })
  },
  async getLogoById ({ state }, logo) {
    return shirtnetwork.getLogo(logo)
  },
  setLogosPerPage ({ getters, commit }, logosPerPage) {
    commit('setLogosPerPage', logosPerPage)
  }
}

// mutations
const mutations = {
  setLogos (state, logos) {
    state.all = logos
  },
  setSelectedLogo (state, logo) {
    state.selected = logo
  },
  setLogoCount (state, count) {
    state.pagination = Object.assign(state.pagination, {
      count: count,
      numPages: Math.ceil(count / state.pagination.itemsPerPage),
      currentPage: 1
    })
  },
  setLogoPage (state, page) {
    state.pagination.currentPage = page
  },
  setLogosPerPage (state, logosPerPage) {
    state.pagination = Object.assign(state.pagination, { itemsPerPage: logosPerPage })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
