import FontFaceObserver from 'fontfaceobserver-es'
import 'whatwg-fetch'

// initial state
const state = {
  all: [],
  bitmap: [],
  selected: undefined,
  preloaded: [],
  fontListUrl: process.env.NODE_ENV !== 'production' ? '//backend.shirtnetwork.de/fontlist' : ''
}

// getters
const getters = {
  allFonts: state => state.all,
  sortedFonts: (state, getters) => [...getters.allowedFonts].sort((a, b) => {
    return a.title > b.title ? 1 : -1
  }),
  allowedFonts: (state, getters, rootState, rootGetters) => getters.allowedPrinttypeFonts(rootGetters.selectedPrinttype),
  allowedPrinttypeFonts: (state, getters) => printtype => {
    if (getters.allowedPrinttypeFontNames(printtype).length > 0) {
      return state.all.filter(f => getters.allowedPrinttypeFontNames(printtype).indexOf(f.title) !== -1)
    } else {
      return state.all
    }
  },
  allowedFontNames: (state, getters, rootState, rootGetters) => getters.allowedPrinttypeFontNames(rootGetters.selectedPrinttype),
  allowedPrinttypeFontNames: (state, getters, rootState, rootGetters) => printtype => printtype && printtype.fontList ? printtype.fontList.split(',') : [],
  matchingFontFamily: (state, getters) => (fontFamily, printtype) => !printtype.fontList || getters.allowedPrinttypeFontNames(printtype).indexOf(fontFamily) !== -1 ? fontFamily : getters.allowedPrinttypeFonts(printtype)[0].title,
  preloadedFonts: state => state.preloaded,
  fontByTitle: state => title => state.all.find(f => f && f.title === title),
  /**
   * Get selected font, can be selected objects font or user selected or first available
   */
  selectedFont: (state, getters, rootState, rootGetters) => {
    if (rootGetters.selectedObject && rootGetters.selectedObject.type === 'text') {
      const font = getters.allowedFonts.find(f => f && f.title === rootGetters.selectedObject.options.fontFamily)
      return font || getters.allowedFonts[0]
    } else if (getters.allowedFonts && getters.allowedFonts.length > 0) {
      return getters.allowedFonts[0]
    }
  }
}

// actions
const actions = {
  loadFontList ({ dispatch, state }) {
    return fetch(state.fontListUrl).then(response => {
      return response.json().then(r => dispatch('setFonts', r))
    })
  },
  setFonts ({ commit, dispatch, getters }, fonts) {
    commit('setFonts', fonts)
    dispatch('setSelectedFont', getters.allowedFonts[0])
  },
  setFontListUrl ({ commit }, url) {
    commit('setFontListUrl', url)
  },
  async setSelectedFont ({ rootState, commit, dispatch }, font) {
    if (!font) return

    await dispatch('preloadFont', font)
    commit('setSelectedFont', font)
    const props = { fontFamily: font.title }
    if (!font.bold) props.fontWeight = 'normal'
    if (!font.italic) props.fontStyle = 'normal'
    dispatch('setObjectProperties', props)
  },
  async preloadFont ({ state, commit }, font) {
    if (!font) return

    if (state.preloaded.indexOf(font) === -1) {
      commit('addPreloadedFont', font)
    }

    var f = [new FontFaceObserver(font.title).load()]

    if (font.bold) {
      f.push(new FontFaceObserver(font.title, { weight: 700 }).load())
    }
    if (font.italic) {
      f.push(new FontFaceObserver(font.title, { style: 'italic' }).load())
    }
    if (font.bold && font.italic) {
      f.push(new FontFaceObserver(font.title, { weight: 700, style: 'italic' }).load())
    }

    return Promise.all(f).catch(reason => {
      console.log(reason)
    })
  },
  setSelectedObject ({ state, dispatch }, object) {
    object && object.type === 'text' && dispatch('setSelectedFont', state.all.find(f => f && f.title === object.options.fontFamily))
  }
}

// mutations
const mutations = {
  setFonts (state, fonts) {
    state.all = fonts
  },
  setSelectedFont (state, font) {
    state.selected = font
  },
  addPreloadedFont (state, font) {
    state.preloaded.push(font)
  },
  setFontListUrl (state, url) {
    state.fontListUrl = url
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
