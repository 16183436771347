// initial state
const state = {
  selected: ''
}

// getters
const getters = {
  selectedToolbarItem: state => state.selected
}

// actions
const actions = {
  setSelectedToolbarItem ({ commit }, item) {
    commit('setSelectedToolbarItem', item)
  },
  toggleSelectedToolbarItem ({ commit }, item) {
    commit('setSelectedToolbarItem', item === state.selected ? '' : item)
  }
}

// mutations
const mutations = {
  setSelectedToolbarItem (state, item) {
    state.selected = item
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
