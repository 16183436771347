import shirtnetwork from '../../lib/shirtnetwork'

// initial state
const state = {
  all: [],
  selected: undefined
}

// getters
const getters = {
  fullLogoCategories: state => [...state.all].sort((a, b) => { return a.ordering > b.ordering }),
  allLogoCategories: (state, getters, rootState, rootGetters) => {
    const sorted = getters.fullLogoCategories
    if (rootGetters.useTemplate && rootGetters.template && rootGetters.template.logo.categories.length) {
      return sorted.filter(c => rootGetters.template.logo.categories.indexOf(c.id) !== -1)
    }
    return sorted
  },
  firstLogoCategory: (state, getters) => getters.allLogoCategories[0],
  selectedLogoCategory: state => state.selected
}

// actions
const actions = {
  getAllLogoCategories ({ getters, rootGetters, commit, dispatch }) {
    if (!state.all.length) {
      return rootGetters.user.then(user => {
        return shirtnetwork.getLogoCategories(user).then(categories => {
          commit('setLogoCategories', categories)
        })
      })
    }
  },
  setSelectedLogoCategory ({ commit, dispatch }, category) {
    commit('setSelectedLogoCategory', category)
    return dispatch('getCategoryLogos', category)
  }
}

// mutations
const mutations = {
  setLogoCategories (state, categories) {
    state.all = categories
  },
  setSelectedLogoCategory (state, category) {
    state.selected = category
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
