// initial state
const state = {
  amount: 1
}

// getters
const getters = {
  amount: (state, getters, rootState, rootGetters) => Math.min(Math.max(state.amount, rootGetters.minProductAmount), rootGetters.maxProductAmount),
  fullAmount: (state, getters, rootState, rootGetters) => rootGetters.selectedSizes.length ? rootGetters.selectedSizes.reduce((amount, size) => amount + size.amount, 0) : getters.amount,
  fullPrice: (state, getters) => getters.isFixPrice ? getters.fixPrice * getters.fullAmount : getters.fullBasePrice * getters.fullAmount + getters.uploadSpecialPrice,
  price: (state, getters) => getters.isFixPrice ? getters.fixPrice * getters.amount : getters.basePrice * getters.amount + getters.uploadSpecialPrice,
  pricePerUnit: (state, getters) => getters.isFixPrice ? getters.productPrice : getters.basePrice,
  minPricePerUnit: (state, getters) => getters.isFixPrice ? getters.productPrice : getters.minBasePrice,
  maxPricePerUnit: (state, getters) => getters.isFixPrice ? getters.productPrice : getters.maxBasePrice,
  basePrice: (state, getters) => getters.productPrice + getters.variantPrice + getters.objectFullPrice + getters.sizePrice + getters.viewPrice,
  minBasePrice: (state, getters) => getters.productPrice + getters.variantPrice + getters.objectFullPrice + getters.minSizePrice + getters.viewPrice,
  maxBasePrice: (state, getters) => getters.productPrice + getters.variantPrice + getters.objectFullPrice + getters.maxSizePrice + getters.viewPrice,
  fullBasePrice: (state, getters) => getters.productPrice + getters.variantPrice + getters.objectFullPrice + getters.fullSizePrice + getters.viewPrice,
  formattedPrice: (state, getters, rootState, rootGetters) => (price) => price ? price.toLocaleString(rootGetters.language, { style: 'currency', currency: rootGetters.currency }) : '',
  fixPrice: (state, getters) => getters.productPrice + getters.variantPrice + getters.sizePrice + getters.viewPrice,
  isFixPrice: (state, getters, rootState, rootGetters) => rootGetters.selectedProduct && rootGetters.selectedProduct.fixPrice,
  productPrice: (state, getters, rootState, rootGetters) => {
    // base price or scale price if applicable
    let price = rootGetters.selectedProduct ? rootGetters.selectedProduct.price : 0
    if (getters.currentProductPriceScale) {
      price = getters.currentProductPriceScale.price
    }
    return Number(price)
  },
  variantPrice: (state, getters, rootState, rootGetters) => rootGetters.selectedVariant ? rootGetters.selectedVariant.price : 0,
  sizePrice: (state, getters, rootState, rootGetters) => rootGetters.selectedSize ? rootGetters.selectedSize.price : 0,
  fullSizePrice: (state, getters, rootState, rootGetters) => rootGetters.selectedSizes.length ? rootGetters.selectedSizes.reduce((price, s) => price + (s.size ? s.size.price : 0), 0) : getters.sizePrice,
  minSizePrice: (state, getters, rootState, rootGetters) => rootGetters.selectedSizes.length ? Math.min(...rootGetters.selectedSizes.map(s => s.size.price)) : getters.sizePrice,
  maxSizePrice: (state, getters, rootState, rootGetters) => rootGetters.selectedSizes.length ? Math.max(...rootGetters.selectedSizes.map(s => s.size.price)) : getters.sizePrice,
  /**
   * Sorted price scales
   */
  productPriceScales: (state, getters, rootState, rootGetters) => {
    if (!rootGetters.selectedProduct || !rootGetters.selectedProduct.pricescales) {
      return []
    }
    // sort by fromAmount
    const scales = rootGetters.selectedProduct.pricescales.source.slice(0)
    const sortBy = fn => (a, b) => -(fn(a) < fn(b)) || +(fn(a) > fn(b))
    const getFromAmount = o => o.fromAmount
    const sortByFromAmount = sortBy(getFromAmount)
    return scales.sort(sortByFromAmount)
  },
  /**
   * Price scales allowed for this amount
   */
  matchingProductPriceScales: (state, getters, rootState, rootGetters) => {
    return getters.productPriceScales.filter(o => o.fromAmount <= state.amount)
  },
  /**
   * Currently matching price scale if any
   */
  currentProductPriceScale: (state, getters, rootState, rootGetters) => {
    return getters.matchingProductPriceScales.length > 0 ? getters.matchingProductPriceScales[getters.matchingProductPriceScales.length - 1] : undefined
  },
  /**
   * Get view price once per view which has an object on it
   */
  viewPrice: (state, getters) => getters.viewPrices.price,
  /**
   * Get views and the full price if there is an object on them
   */
  viewPrices: (state, getters, rootState, rootGetters) => rootGetters.objects.reduce(function (accumulator, object) {
    if (object.meta.view.price > 0 && accumulator.views.indexOf(object.meta.view) === -1) {
      accumulator.views.push(object.meta.view)
      accumulator.price += object.meta.view.price
    }
    return accumulator
  }, { views: [], price: 0 }),
  /**
   * Get price of all objects, depending on usePixelPrice flag active or not
   */
  objectFullPrice: (state, getters, rootState, rootGetters) => rootGetters.objects.reduce(function (price, object) {
    return price + getters.objectPrice(object)
  }, 0),
  /**
   * Get price of a single object
   */
  objectPrice: (state, getters, rootState, rootGetters) => (object) => {
    let price = 0

    if (rootGetters.selectedProduct.usePixelPrice) {
      price += getters.objectPixelPrice(object)
    } else {
      price += getters.objectDefaultPrice(object)
    }

    price += getters.objectColorPrice(object)

    return price
  },
  /**
   * Get cm2 price of an object
   */
  objectPixelPrice: (state, getters, rootState, rootGetters) => (object) => {
    if (!object.meta.printtype) return 0

    const dimensions = rootGetters.objectDimensions(object)
    const dimPrice = dimensions.size * getters.objectPixelPriceScale(object)
    let minPrice = 0
    let maxPrice = Infinity

    if (object.type === 'text') {
      minPrice = rootGetters.selectedProduct.minTextPrice
      maxPrice = rootGetters.selectedProduct.maxTextPrice || Infinity
    } else if (object.type === 'user-logo') {
      minPrice = rootGetters.selectedProduct.minUploadPrice
      maxPrice = rootGetters.selectedProduct.maxUploadPrice || Infinity
    } else if (object.type === 'logo') {
      minPrice = object.meta.logo.minPrice
      maxPrice = object.meta.logo.maxPrice || Infinity
    }

    return Math.max(Math.min(dimPrice, maxPrice), minPrice)
  },
  /**
   * Get price of an object if usePixelPrice is inactive
   */
  objectDefaultPrice: (state, getters, rootState, rootGetters) => (object) => {
    try {
      switch (object.type) {
        case 'logo':
          return object.meta.logo.price + object.meta.logo.provision
        case 'text':
          return rootGetters.selectedProduct.textBlockPrice +
            (rootGetters.selectedProduct.textPriceMode === 'PER_LINE'
              ? (object.options.text.match(/^\s*\S/gm).length * rootGetters.selectedProduct.textPrice)
              : (object.options.text.length * rootGetters.selectedProduct.textPrice))
        case 'user-logo':
          return rootGetters.selectedProduct.uploadPrice + rootGetters.selectedVariant.uploadPrice + (object.meta.printtype ? object.meta.printtype.uploadPrice : 0)
      }
    } catch (ex) {
    }

    return 0
  },
  /**
   * Get price of object colors
   */
  objectColorPrice: (state, getters, rootState, rootGetters) => (object) => {
    let price = 0
    if (object.meta.printtype && object.options.fills) {
      const colors = rootGetters.colorsByPrinttypeFills(object.meta.printtype, object.options.fills)
      price = colors.reduce((a, b) => a + (b ? b.price : 0), 0) // Sum by price field
    }
    return price
  },
  /**
   * Get upload special price once for every upload
   */
  uploadSpecialPrice: (state, getters, rootState, rootGetters) => rootGetters.objects.reduce(function (price, object) {
    if (object.meta.printtype && object.type === 'user-logo') {
      price += object.meta.printtype.uploadSpecialPrice
    }
    return price
  }, 0),
  /**
   *
   */
  objectPixelPriceScale: (state, getters, rootState, rootGetters) => (object) => {
    if (!object.meta.printtype) return 0

    let pixelPrice = object.meta.printtype.pixelPrice
    const scales = rootGetters.printtypePixelPriceScales(object.meta.printtype)

    if (scales) {
      const dimensions = rootGetters.objectDimensions(object)
      scales.forEach(scale => {
        if (scale.size < dimensions.size) {
          pixelPrice = scale.price
        }
      })
    }

    return pixelPrice
  }
}

// actions
const actions = {
  setAmount ({ commit }, amount) {
    commit('setAmount', amount)
  }
}

// mutations
const mutations = {
  setAmount (state, amount) {
    state.amount = amount
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
