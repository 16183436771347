export default {
  instances: [],
  promises: [],
  setFabric (view, fabric) {
    const v = this.instances.find((i) => {
      return i.view.id === view.id
    })
    if (v) {
      v.fabric = fabric
    } else {
      this.instances.push({ view: view, fabric: fabric })
    }
    this._resolvePromises(view)
  },
  async getFabric (view) {
    if (!view) {
      return new Promise((resolve, reject) => reject(new Error('no view parameter given')))
    }
    const i = this.instances.find((i) => {
      return i.view.id === view.id
    })
    if (i && i.fabric) {
      return new Promise((resolve, reject) => resolve(i.fabric))
    } else {
      // wait until this instance is ready
      return new Promise((resolve, reject) => {
        this.promises.push({ view: view, resolve: resolve, reject: reject })
      })
    }
  },
  unsetFabric (view) {
    const i = this.instances.find((i) => {
      return i.view.id === view.id
    })
    if (i) {
      this.instances.splice(this.instances.indexOf(i), 1)
    }
  },
  reset () {
    this.instances = []
  },
  _getFabric (view) {
    return this.instances.find((i) => {
      return i.view.id === view.id
    })
  },
  _resolvePromises (view) {
    this.promises.forEach(p => {
      if (p.view.id === view.id) {
        p.resolve(this._getFabric(view).fabric)
      }
    })
  }
}
