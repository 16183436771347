// initial state
const state = {
  useStock: false,
  stocks: [

    { variant: 'navy', size: '', stock: 25 },
    { variant: 'navy', size: 's-m', stock: 15 }

  ]
}

// getters
const getters = {
  stocks: state => state.stocks,
  useStock: state => state.useStock,
  variantStocks: (state, getters, rootState, rootGetters) => rootGetters.selectedVariant ? getters.stocks.filter(s => s.variant === rootGetters.selectedVariant.artnr) : [],
  sizeStock: (state, getters) => (size) => getters.sizeStockObject(size) ? getters.sizeStockObject(size).stock : 0,
  sizeStockObject: (state, getters) => (size) => getters.variantStocks.find(s => s.size === size.artnr),
  stock: (state, getters) => getters.stockObject ? getters.stockObject.stock : 0,
  stockObject: (state, getters, rootState, rootGetters) => {
    if (rootGetters.productSizes.length) {
      return rootGetters.selectedSize ? getters.variantStocks.find(s => s.size === rootGetters.selectedSize.artnr) : undefined
    } else {
      return getters.variantStocks[0]
    }
  }
}

// actions
const actions = {
  setStocks ({ commit }, stocks) {
    commit('setStocks', stocks)
  },
  setUseStock ({ commit }, useStock) {
    commit('setUseStock', useStock)
  }
}

// mutations
const mutations = {
  setStocks (state, stocks) {
    state.stocks = stocks
  },
  setUseStock (state, useStock) {
    state.useStock = useStock
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
