import Vue from 'vue'
import shirtnetwork from '../../lib/shirtnetwork'

/**
 * Vuex module for managing global values.
 * @namespace Vuex Modules
 * @module store-global
 */

// initial state
var userResolve = null
const userPromise = new Promise((resolve) => { userResolve = resolve })
const emitter = new Vue()

const state = {
  user: undefined,
  hasCoins: undefined,
  apiUrl: '//api.shirtnetwork.de',
  loadCSS: true,
  preloading: true,
  loading: false,
  localVars: {},
  uploadServer: process.env.NODE_ENV !== 'production' ? process.env.VUE_APP_DEV_UPLOAD_SERVER : '',
  showAfterSalesModal: false,
  showDevTools: process.env.NODE_ENV !== 'production',
  mobileMaxWidth: 768,
  screenHeight: 0,
  isMobile: false,
  stickyToolbarHeight: 0,
  hasInputFocus: false,
  messages: []
}

const getters = {
  /**
   * Get current user object
   * @return Promise
   */
  user: state => { return userPromise },
  hasCoins: state => state.hasCoins,
  apiUrl: state => state.apiUrl,
  preloading: state => state.preloading,
  loading: state => state.loading,
  uploadServer: state => state.uploadServer,
  loadCSS: state => state.loadCSS,
  localVar: (state) => (key) => state.localVars[key] ? state.localVars[key] : {},
  localVars: state => state.localVars,
  showAfterSalesModal: state => state.showAfterSalesModal,
  showDevTools: state => state.showDevTools,
  isMobile: state => state.isMobile,
  mobileMaxWidth: state => state.mobileMaxWidth,
  screenHeight: state => state.screenHeight,
  cssScreenHeight: state => state.screenHeight ? state.screenHeight + 'px' : '100vh',
  stickyToolbarHeight: state => state.stickyToolbarHeight,
  hasInputFocus: state => state.hasInputFocus,
  messages: state => state.messages,
  formattedNumber: (state, getters, rootState, rootGetters) => (n) => Number(n).toLocaleString(rootGetters.language, { maximumFractionDigits: 2 })
}

const actions = {
  setUser ({ commit }, user) {
    commit('setUser', user)
  },
  setPreloading ({ commit }, preloading) {
    commit('setPreloading', preloading)
  },
  setLoading ({ commit }, loading) {
    commit('setLoading', loading)
  },
  setUploadServer ({ commit }, uploadServer) {
    commit('setUploadServer', uploadServer)
  },
  setLocalVar ({ commit }, { localVar, value }) {
    commit('setLocalVar', { localVar, value })
  },
  setShowAfterSalesModal ({ commit }, show) {
    commit('setShowAfterSalesModal', show)
  },
  setShowDevTools ({ commit }, show) {
    commit('setShowDevTools', show)
  },
  setLoadCSS ({ commit }, load) {
    commit('setLoadCSS', load)
  },
  async getHasCoins ({ getters, commit }) {
    return getters.user.then(user => {
      return shirtnetwork.hasCoins(user).then(hasCoins => {
        commit('setHasCoins', hasCoins)
      })
    })
  },
  setIsMobile ({ commit, dispatch }, isMobile) {
    commit('setIsMobile', isMobile)
    // dispatch('setEnableInlineTextEditing', !isMobile)
  },
  setScreenHeight ({ commit, dispatch }, screenHeight) {
    commit('setScreenHeight', screenHeight)
    // dispatch('setEnableInlineTextEditing', !isMobile)
  },
  setStickyToolbarHeight ({ commit }, stickyToolbarHeight) {
    commit('setStickyToolbarHeight', stickyToolbarHeight)
  },
  setHasInputFocus ({ commit }, hasInputFocus) {
    commit('setHasInputFocus', hasInputFocus)
  },
  addMessage ({ commit }, { message, options }) {
    commit('addMessage', { message, options })
  },
  // Allow users to hand cache key to app
  setCacheKey ({ commit }, key) {
    shirtnetwork.setCacheKey(key)
  },
  // Event Bus for external handling, not to be used internally - use vuex instead
  observe ({ dispatch, commit }, { event, callback }) {
    emitter.$on(event, callback)
  },
  notify ({ state }, { event, payload }) {
    emitter.$emit(event, payload)
  }
}

const mutations = {
  setUser (state, user) {
    const curUser = state.user
    state.user = user
    if (curUser !== user) userResolve(user)
  },
  setPreloading (state, preloading) {
    state.preloading = preloading
  },
  setLoading (state, loading) {
    state.loading = loading
  },
  setUploadServer (state, uploadServer) {
    state.uploadServer = uploadServer
  },
  setLocalVar (state, { localVar, value }) {
    Vue.set(state.localVars, localVar, value)
  },
  setHasCoins (state, hasCoins) {
    state.hasCoins = hasCoins
  },
  setShowAfterSalesModal (state, show) {
    state.showAfterSalesModal = show
  },
  setShowDevTools (state, show) {
    state.showDevTools = show
  },
  setLoadCSS (state, load) {
    state.loadCSS = load
  },
  setIsMobile (state, isMobile) {
    state.isMobile = isMobile
  },
  setScreenHeight (state, screenHeight) {
    state.screenHeight = screenHeight
  },
  setStickyToolbarHeight (state, stickyToolbarHeight) {
    state.stickyToolbarHeight = stickyToolbarHeight
  },
  setHasInputFocus (state, hasInputFocus) {
    state.hasInputFocus = hasInputFocus
  },
  addMessage (state, { message, options }) {
    state.messages.push({ message, options })
  }
}

export default {
  /**
   * vuex state
   * @namespace State
   */
  state,
  /**
   * vuex getters
   */
  getters,
  /**
   * vuex actions
   */
  actions,
  /**
   * vuex mutations
   */
  mutations
}
