<template>
  <div
    id="designer-app"
    :style="isMobile ? {height: cssScreenHeight} : ''"
  >
    <DesktopInterface
      v-if="!isPreloading && !isMobile"
    />
    <MobileInterface v-else-if="!isPreloading" />

    <LoadingScreen v-if="isPreloading" />

    <DevToolbar v-if="showDevTools" />
    <FontPreloader />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import LoadingScreen from './components/interface/LoadingScreen'
import FontPreloader from './components/font/FontPreloader.vue'
const debug = require('debug')('shirtnetwork:InputFocus')
/**
 * Preloading the component sets is not externalizable through to webpack limitations
 * so the code is duplicated here as well as in interfaces
 */

export default {
  name: 'ShirtnetworkDesigner',
  components: {
    MobileInterface: async () => {
      const module = await import('bootstrap-vue/src/')
      // monkey patch focusFirst until there is an option to suppress this
      module.BModal.extendOptions.methods.focusFirst = function () {
        debug('focus first replaced')
      }
      module.BModal.extendOptions.methods.focusHandler = function () {
        debug('focus handler replaced')
      }
      Vue.use(module.default)
      return import('./components/interface/mobile/MobileInterface')
    },
    DesktopInterface: async () => {
      const module = await import('bootstrap-vue/src/')
      Vue.use(module.default)
      return import('./components/interface/desktop/DesktopInterface')
    },
    DevToolbar: async () => {
      const module = await import('bootstrap-vue/src/')
      Vue.use(module.default)
      return import('./components/dev/DevToolbar')
    },
    FontPreloader,
    LoadingScreen
  },
  data: function () {
    return {
      userId: undefined
    }
  },
  computed: {
    isPreloading: function () {
      return this.preloading || !this.hasCoins
    },
    ...mapGetters(['preloading', 'isMobile', 'showDevTools', 'hasCoins', 'mobileMaxWidth', 'stickyToolbarHeight', 'showDevTools', 'cssScreenHeight'])
  },
  mounted: function () {
    document.addEventListener('focusin', function (ev) {
      debug('focusin', document.activeElement, ev.target, ev)
    }, true)
    document.addEventListener('focusout', function (ev) {
      debug('focusout', ev.relatedTarget, document.activeElement, ev)
    }, true)

    this.onWindowResize()
    this.getHasCoins().then(() => {
      if (!this.hasCoins) {
        this.$alert(this.$t('Der Designer steht im Moment leider nicht zur Verfügung, bitte versuchen Sie es später noch einmal.') + ' (Statuscode: #100 NO_COINS)', { title: this.$t('Hinweis') })
      }
    })
    if (process.env.NODE_ENV !== 'production') {
      window.addEventListener('keydown', this.onKeyDown, true)
    }
  },
  methods: {
    onWindowResize () {
      const isMobileDevice = navigator.userAgent.match(/Android/i) ||
          navigator.userAgent.match(/webOS/i) ||
          navigator.userAgent.match(/iPhone/i) ||
          navigator.userAgent.match(/iPad/i) ||
          navigator.userAgent.match(/iPod/i) ||
          navigator.userAgent.match(/BlackBerry/i) ||
          navigator.userAgent.match(/Windows Phone/i)

      /*
      const mql = window.matchMedia('(orientation: portrait)')

      const detectDeviceObj = {
        device: navigator.maxTouchPoints ? 'mobile' : 'computer',
        orientation: !navigator.maxTouchPoints ? 'desktop' : mql.matches ? 'portrait' : 'landscape'
      }
      this.setIsMobile((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) <= this.mobileMaxWidth || (detectDeviceObj.device === 'mobile' && detectDeviceObj.orientation === 'landscape'))
       */
      this.setScreenHeight(document.documentElement ? document.documentElement.clientHeight : window.innerHeight)
      this.setIsMobile(!!isMobileDevice)
      this.setHtmlClass()
    },
    onKeyDown (ev) {
      if (ev.keyCode === 68 && ev.shiftKey === true) {
        this.setShowDevTools(!this.showDevTools)
        ev.preventDefault()
      }
    },
    setHtmlClass () {
      if (this.isMobile) {
        document.documentElement.classList.add('is-mobile')
        document.documentElement.classList.remove('is-desktop')
      } else {
        document.documentElement.classList.remove('is-mobile')
        document.documentElement.classList.add('is-desktop')
      }
    },
    ...mapActions(['getHasCoins', 'setIsMobile', 'setShowDevTools', 'setScreenHeight'])
  }
}
</script>

<style>
</style>

<tpl></tpl>
