export default {
  instances: [],
  addUppy (uppy) {
    if (!this.getUppy(uppy.getID())) {
      this.instances.push(uppy)
    }
  },
  getUppy (id) {
    return this.instances.find(inst => inst.getID() === id)
  },
  removeUppy (id) {
    this.instances.splice(this.instances.findIndex(inst => inst.getID() === id), 1)
  },
  reset () {
    this.instances = []
  }
}
