<template>
  <div>
    <link
      v-for="font in filteredFonts"
      :key="font.title"
      :href="font.path"
      rel="stylesheet"
      type="text/css"
    >
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'FontPreloader',
  computed: {
    filteredFonts: function () {
      return this.preloadedFonts.filter(f => f)
    },
    ...mapGetters({
      preloadedFonts: 'preloadedFonts'
    })
  },
  created () {
    this.loadFontList()
  },
  methods: {
    ...mapActions(['loadFontList'])
  }
}
</script>

<style scoped>

</style>
