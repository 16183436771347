import FabricManager from '../managers/FabricManager'
import Vue from 'vue'
const debug = require('debug')('shirtnetwork:store:variantViews')

// initial state
const state = {
  all: [],
  old: [],
  previews: {},
  selected: undefined
}

// getters
const getters = {
  allVariantViews: state => [...state.all].sort((a, b) => { return a.ordering > b.ordering }),
  selectedVariantView: state => state.selected,
  firstVariantView: state => state.all[0],
  selectedVariantViewIndex: state => state.all.indexOf(state.selected),
  variantViewById: state => (id) => state.all.find(v => v.id === id),
  variantViewByKey: state => (key) => state.all.find(v => v.key === key),
  variantViewPreviews: state => state.previews,
  variantViewPreviewByView: state => (view) => view ? state.previews[view.id] : undefined
}

// actions
const actions = {
  async setVariantViews ({ state, commit, dispatch }, views) {
    commit('setOldVariantViews', state.all.slice(0))
    commit('setVariantViews', views)
    FabricManager.reset()
    debug('variant views set')
    dispatch('setSelectedVariantView', views[0])
    debug('return from variant views set')
  },
  setSelectedVariantView ({ commit, dispatch }, view) {
    commit('setSelectedVariantView', view)
    debug('selected variant view set', view)
    dispatch('setSelectedObject', undefined)
  },
  selectNextVariantView ({ getters, dispatch }) {
    const currentIndex = getters.selectedVariantViewIndex
    const nextIndex = currentIndex < getters.allVariantViews.length - 1 ? currentIndex + 1 : 0
    return dispatch('setSelectedVariantView', getters.allVariantViews[nextIndex])
  },
  selectPreviousVariantView ({ getters, dispatch }) {
    const currentIndex = getters.selectedVariantViewIndex
    const prevIndex = currentIndex > 0 ? currentIndex - 1 : getters.allVariantViews.length - 1
    return dispatch('setSelectedVariantView', getters.allVariantViews[prevIndex])
  },
  setFabric ({ state, dispatch }, { view, fabric }) {
    FabricManager.setFabric(view, fabric)
    const oldView = state.old.find((v) => {
      return v.key === view.key
    })

    if (oldView) {
      if (oldView.id !== view.id) {
        FabricManager.unsetFabric(oldView)
      }
      return dispatch('restoreObjects', { oldView: oldView, newView: view })
    }
  },
  setVariantViewPreview ({ state, commit, dispatch }, { view, preview }) {
    commit('setVariantViewPreview', { view, preview })
  }
}

// mutations
const mutations = {
  setVariantViews (state, views) {
    state.all = views
  },
  setOldVariantViews (state, oldViews) {
    state.old = oldViews
  },
  setSelectedVariantView (state, view) {
    state.selected = view
  },
  setVariantViewPreview (state, { view, preview }) {
    Vue.set(state.previews, view.id, preview)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
