import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

// Autoload all modules in modules folder
var context = require.context('./modules/', true, /\.(js)$/)
var modules = {}

context.keys().forEach((fn) => {
  modules[fn.substr(0, fn.lastIndexOf('.')).replace('./', '')] = context(fn).default
})

/**
 * The Shirtnetwork Vuex store
 */
const store = new Vuex.Store({
  modules: modules,
  strict: debug,
  plugins: []
})

// Allows modules to watch for store properties and auto dispatch an action
Object.entries(modules).forEach((module) => {
  var watchers = module[1].watchers
  if (watchers) {
    Object.entries(watchers).forEach((w) => {
      store.watch(() => store.getters[w[0]], () => {
        store.dispatch(w[1])
      })
    })
  }
})

export default store
