import FabricManager from '../managers/FabricManager'
const debug = require('debug')('shirtnetwork:store:config')

const state = {
  isTemplate: false,
  template: {
    settings: {
      showToolbar: true
    },
    product: {
      allowChange: true,
      minimumAmount: 0,
      maximumAmount: 0,
      packageUnit: 0,
      title: undefined,
      price: 0,
      fixPrice: false
    },
    logo: {
      categories: []
    },
    size: {
      allowChange: true
    },
    variant: {
      allowChange: true
    },
    printtype: {
      allowChange: true,
      mode: undefined,
      allowed: []
    },
    objects: {
      allowText: true,
      allowLogo: true,
      allowUpload: true,
      allowCustom: true
    }
  },
  useTemplate: false
}

const getters = {
  isTemplate: state => state.isTemplate,
  template: state => state.template,
  useTemplate: state => state.useTemplate,
  canChangeProduct: state => state.useTemplate && state.template ? state.template.product.allowChange : true,
  canChangeVariant: state => state.useTemplate && state.template ? state.template.variant.allowChange : true,
  canChangeSize: state => state.useTemplate && state.template ? state.template.size.allowChange : true,
  canChangePrinttype: state => state.useTemplate && state.template ? state.template.printtype.allowChange : true,
  canAddText: state => state.useTemplate && state.template ? state.template.objects.allowText : true,
  canAddLogo: state => state.useTemplate && state.template ? state.template.objects.allowLogo : true,
  canAddUpload: state => state.useTemplate && state.template ? state.template.objects.allowUpload : true,
  canAddCustomObject: state => state.useTemplate && state.template ? state.template.objects.allowCustom : true
}

// actions
const actions = {
  async boot ({ dispatch, rootGetters }, config) {
    dispatch('setPreloading', true)
    dispatch('setUser', config.user)

    debug('booting ...')
    debug('... set product')
    await dispatch('setSelectedProductBySku', config.product)
    debug('done set product!')
    if (config.variant) {
      try {
        debug('... set variant')
        await dispatch('setSelectedVariantBySku', config.variant)
        debug('done set variant!')
      } catch (e) { debug(e) }
    }
    if (config.size) {
      try {
        debug('... set size')
        await dispatch('setSelectedSizeBySku', config.size)
        debug('done set size!')
      } catch (e) { debug(e) }
    }
    if (config.logo) {
      try {
        debug('... set logo')
        const logo = await dispatch('getLogoById', { id: config.logo })
        // do not await this, there is no fabric instance here currently
        dispatch('addLogo', logo)
        debug('done set logo!')
      } catch (e) { debug(e) }
    }
    if (config.text) {
      try {
        debug('... set text')
        if (!config.font) {
          await dispatch('loadFontList')
        }
        // do not await this, there is no fabric instance here currently
        dispatch('addText', { text: config.text, options: { fontFamily: config.font || rootGetters.sortedFonts[0].title } })
      } catch (e) { debug(e) }
    }
    debug('... booting finished!')
    dispatch('setPreloading', false)
  },
  async applyConfig ({ dispatch, rootGetters }, config) {
    dispatch('setPreloading', true)
    dispatch('setUser', config.user)
    debug('applying config ...')
    debug('... set product')
    await dispatch('setSelectedProduct', { id: config.product })
    debug('... set variant')
    await dispatch('setSelectedVariant', { id: config.variant })
    debug('... load objects')
    await dispatch('loadConfigObjects', config.objects)

    dispatch('setPreloading', false)
  },
  async loadConfigObjects ({ dispatch, rootGetters }, objects) {
    for (var i = 0; i < objects.length; i++) {
      var object = Object.assign({}, objects[i])

      const enlivenedObject = {
        meta: Object.assign({}, object.meta, {
          printtype: object.meta.printtype ? rootGetters.printtypeById(object.meta.printtype.id) : undefined,
          view: rootGetters.variantViewByKey(object.meta.view.key),
          wasRestored: true
        })
      }

      if (object.type === 'text') {
        await dispatch('preloadFont', rootGetters.fontByTitle(object.options.fontFamily))
      } else if (object.type === 'logo') {
        var logo = await dispatch('getLogoById', { id: object.meta.logo.id })
        enlivenedObject.meta.logo = logo
      }

      // Shift offset
      // object.options.left = 0
      // object.options.top = 0
      // enlivenedObject.meta.offset.x = enlivenedObject.meta.offset.y = 0

      debug('loading object, will set at', object.options.left)

      dispatch('addObject', (Object.assign({}, object, enlivenedObject)))
    }
  },
  async exportFile ({ dispatch, rootGetters }) {
    var objects = await dispatch('exportObjects')
    debug(process.env)
    debug('do export ...')
    const state = {
      user: await rootGetters.user,
      product: rootGetters.selectedProduct.id,
      variant: rootGetters.selectedVariant.id,
      size: rootGetters.selectedSize ? rootGetters.selectedSize.id : undefined,
      views: await dispatch('exportViews'),
      objects: objects.sort((a, b) => +(a.meta.index > b.meta.index) || +(a.meta.index === b.meta.index) - 1),
      template: rootGetters.isTemplate ? rootGetters.template : false,
      client: {
        userAgent: navigator.userAgent,
        viewPortWidth: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
        viewPortHeight: Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
      },
      version: process.env.VUE_APP_VERSION
    }
    debug('... exported')
    return state
  },
  async exportViews ({ rootGetters }) {
    debug('export views')
    return Promise.all(rootGetters.allVariantViews.map(async (v) => {
      debug('view', v.key)
      const fabric = await FabricManager.getFabric(v)
      debug('fabric ready for view', v.key)
      return {
        id: v.id,
        key: v.key,
        name: v.name,
        picture: v.picture,
        mask: v.mask,
        overlay: v.overlay,
        svg: fabric.getCanvas().toSVG(),
        dimensions: {
          left: v.left,
          top: v.top,
          right: v.right,
          bottom: v.bottom,
          width: v.width,
          height: v.height,
          realWidth: v.realWidth,
          realHeight: v.realHeight
        },
        backgroundColor: fabric.getCanvas().innerBackgroundColor
      }
    })
    )
  },
  async exportObjects ({ rootGetters }) {
    debug('export objects')
    return Promise.all(rootGetters.objects.map(async (o) => {
      debug('object', o)
      const fabric = await FabricManager.getFabric(o.meta.view)
      const exportedObject = Object.assign({}, o, {
        meta: Object.assign({}, o.meta, {
          printtype: o.meta.printtype ? { id: o.meta.printtype.id, name: o.meta.printtype.name } : undefined,
          view: {
            id: o.meta.view.id,
            key: o.meta.view.key
          },
          fills: o.options.fills.map((f) => {
            const color = rootGetters.colorByPrinttypeFill(o.meta.printtype, f)
            return color ? {
              id: color.id,
              name: color.name,
              hex: color.hex
            } : null
          }),
          offset: await fabric.getOffset(),
          dimensions: rootGetters.objectDimensions(o),
          index: fabric.getCanvas().getObjects().indexOf(fabric.getObjectByUID(o.id))
        })
      })

      if (o.type === 'logo') {
        exportedObject.meta.logo = {
          id: o.meta.logo.id,
          picture: o.meta.logo.svg || o.meta.logo.picture,
          provision: o.meta.logo.provision,
          designer: o.meta.logo.designer
        }
      }

      return exportedObject
    }))
  },
  async exportSVG ({ rootGetters }) {
    return Promise.all(rootGetters.allVariantViews.map(async (v) => {
      const fabric = await FabricManager.getFabric(v)
      return { key: v.key, svg: fabric.getCanvas().toSVG() }
    }))
  },
  async storeLocalConfig ({ dispatch }) {
    const config = await dispatch('exportFile')
    window.localStorage.setItem('shirtnetworkConfig', JSON.stringify(config))
  },
  async clearLocalConfig ({ dispatch }) {
    window.localStorage.removeItem('shirtnetworkConfig')
  },
  async loadLocalConfig ({ dispatch }, keepObjects) {
    const json = window.localStorage.getItem('shirtnetworkConfig')
    const config = json ? JSON.parse(json) : undefined
    if (config) {
      if (!keepObjects) {
        await dispatch('removeAllObjectsFromFabric')
      }
      return dispatch('loadConfigObjects', config.objects)
    }
  },
  setIsTemplate ({ commit }, isTemplate) {
    commit('setIsTemplate', isTemplate)
  },
  setTemplate ({ commit }, template) {
    commit('setTemplate', template)
  },
  setUseTemplate ({ commit, dispatch }, useTemplate) {
    commit('setUseTemplate', useTemplate)
    dispatch('applyObjectTemplates')
  }
}

const mutations = {
  setIsTemplate (state, isTemplate) {
    state.isTemplate = isTemplate
  },
  setTemplate (state, template) {
    state.template = Object.assign({}, template)
  },
  setUseTemplate (state, useTemplate) {
    state.useTemplate = useTemplate
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
