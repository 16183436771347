import shirtnetwork from '../../lib/shirtnetwork'

// initial state
const state = {
  all: [],
  selected: undefined
}

// getters
const getters = {
  allCategories: state => [...state.all].sort((a, b) => { return a.ordering < b.ordering }),
  selectedCategory: state => state.selected
}

// actions
const actions = {
  async getAllCategories ({ rootGetters, commit, dispatch, state }) {
    return rootGetters.user.then(user => {
      return shirtnetwork.getCategories(user).then(categories => {
        categories.find(c => c.id === -1) || categories.unshift({ name: 'Alle', id: -1, ordering: -9999 })
        commit('setCategories', categories)
        state.selected || dispatch('setFirstCategoryActive')
      })
    })
  },
  async setFirstCategoryActive ({ getters, commit, dispatch }) {
    return dispatch('setSelectedCategory', getters.allCategories[0])
  },
  async setSelectedCategory ({ commit, dispatch }, category) {
    commit('setSelectedCategory', category)
    await dispatch('getCategoryProducts', category)
    return dispatch('getProductFilter', category)
  }
}

// mutations
const mutations = {
  setCategories (state, categories) {
    state.all = categories
  },
  setSelectedCategory (state, category) {
    state.selected = category
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
