// initial state
const state = {
  selected: undefined,
  multiSelected: []
}

// getters
const getters = {
  productSizes: (state, getters, rootState, rootGetters) => rootGetters.selectedProduct ? rootGetters.selectedProduct.sizes.source : [],
  selectedSize: (state, getters) => getters.productSizes.indexOf(state.selected) !== -1 ? state.selected : getters.productSizes[0],
  selectedSizes: (state) => state.multiSelected,
  sizeById: (state, getters) => (id) => getters.productSizes.find(s => s.id === id)
}

// actions
const actions = {
  setSelectedSize ({ commit }, size) {
    commit('setSelectedSize', size)
  },
  setSelectedSizeBySku ({ dispatch, getters }, sku) {
    const size = getters.productSizes.find(s => s.artnr === sku)
    size && dispatch('setSelectedSize', size)
  },
  setSelectedSizes ({ commit }, sizes) {
    commit('setSelectedSizes', sizes)
  }
}

// mutations
const mutations = {
  setSelectedSize (state, size) {
    state.selected = size
  },
  setSelectedSizes (state, sizes) {
    state.multiSelected = sizes
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
