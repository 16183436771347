import shirtnetwork from '../../lib/shirtnetwork'
const debug = require('debug')('shirtnetwork:store:variants')

// initial state
const state = {
  all: [],
  selected: undefined
}

// getters
const getters = {
  sortedVariants: state => [...state.all].sort((a, b) => { return a.ordering > b.ordering ? 1 : -1 }),
  selectedVariant: (state, getters) => state.selected ? getters.allVariants.find(v => v.id === state.selected.id) : undefined,
  allVariants: (state, getters) => {
    return getters.sortedVariants.map(v => {
      const variant = Object.assign({}, v)
      variant.color = '#' + Number(v.color).toString(16).padStart(6, '0')
      return variant
    })
  }
}

// actions
const actions = {
  async getProductVariants ({ rootState, state, commit, dispatch, getters }) {
    return shirtnetwork.getVariants(rootState.products.selected).then(async function (variants) {
      commit('setVariants', variants)
      debug('variants set')
      if (getters.allVariants.indexOf(getters.selectedVariant) === -1) await dispatch('setFirstVariantActive')
      debug('return from variants set')
    })
  },
  async setFirstVariantActive ({ getters, dispatch }) {
    if (getters.allVariants[0]) { await dispatch('setSelectedVariant', getters.allVariants[0]) }
  },
  async setSelectedVariant ({ rootState, commit, dispatch }, variant) {
    return shirtnetwork.getVariant(variant).then(async function (variant) {
      commit('setSelectedVariant', variant)
      debug('selected variant set')
      await dispatch('setVariantViews', variant.views.source)
    })
  },
  async setSelectedVariantBySku ({ rootGetters, state, commit, dispatch }, sku) {
    debug('setSelectedVariantBySku 1')
    const user = await rootGetters.user
    const variant = await shirtnetwork.getVariantBySku(user, rootGetters.selectedProduct.artNr, sku)
    commit('setSelectedVariant', variant)
    await dispatch('setVariantViews', variant.views.source)
  }
}

// mutations
const mutations = {
  setVariants (state, variants) {
    state.all = variants
  },
  setSelectedVariant (state, variant) {
    state.selected = variant
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
