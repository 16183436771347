module.exports = {
  add_to_cart: 'In den Warenkorb',
  multi_size_info: 'Der gestaltete Artikel kann in mehreren Größen bestellt werden. Bitte wählen Sie Ihre Größe und die gewünschte Anzahl aus.',
  item_added_to_cart_message: 'Ihr Produkt wurde erfolgreich in den Warenkorb gelegt, was möchten Sie jetzt tun?',
  go_to_cart: 'Zum Warenkorb',
  go_to_cart_info: 'Wechseln Sie zum Warenkorb um den Kauf abzuschließen',
  reset_configuration: 'Neu beginnen',
  reset_configuration_info: 'Erstellen Sie ein neues Produkt, vorhandene Elemente werden gelöscht',
  continue_product: 'Weiteres Produkt',
  continue_product_info: 'Erstellen Sie ein neues Produkt, vorhandene Elemente werden beibehalten',
  choose_size_amount: 'Größe und Anzahl auswählen',
  choose_amount: 'Anzahl auswählen',
  size: 'Größe',
  real_width: 'Breite in cm',
  real_height: 'Höhe in cm',
  increase_object_size: 'Objekt vergrößern',
  decrease_object_size: 'Objekt verkleinern',
  must_choose_size: 'Wählen Sie mindestens eine Größe aus um fortzufahren',
  color: 'Farbe',
  remove_white: 'Hintergrund entfernen',
  remove_white_info: 'Entfernt den weißen Hintergrund Ihres Bildes, eventuell entstehende Artefakte an den Rändern werden später vollständig von unserer Grafikabteilung entfernt',
  loading_data: 'Daten werden geladen ...',
  change_text: 'Text ändern',
  cancel: 'Abbruch',
  apply: 'Übernehmen',
  default_text: 'Mein Text',
  align_object: 'Objekt ausrichten',
  align_object_horizontal_center: 'Horizontal zentrieren',
  align_object_vertical_center: 'Vertikal zentrieren',
  flip_horizontal: 'Horizontal spiegeln',
  flip_vertical: 'Vertikal spiegeln',
  object_stack_up: 'Objekt nach vorne holen',
  object_stack_front: 'In den Vordergrund',
  object_stack_forward: 'Nach vorne',
  object_stack_down: 'Objekt nach vorne holen',
  object_stack_back: 'Nach hinten',
  object_stack_backwards: 'In den Hintergrund',
  object_more_settings: 'Weitere Einstellungen',
  object_transparency: 'Transparenz',
  object_line_height: 'Zeilenabstand',
  object_angle: 'Drehung',
  attention_please: 'Bitte beachten',
  product: 'Produkt',
  copy_link: 'Link kopieren',
  share: 'Teilen',
  share_product: 'Produkt teilen',
  share_design: 'Design teilen',
  modify_design: 'Design anpassen',
  done: 'Fertig',
  font: 'Schriftart',
  choose_font: 'Schriftart wählen',
  alignment: 'Ausrichtung',
  logo: 'Motiv',
  select_logo: 'Motiv wählen',
  text: 'Text',
  line: 'Zeile',
  upload: 'Hochladen',
  upload_file: 'Datei hochladen',
  add_image_or_text: 'Bild/Text',
  cart: 'Warenkorb',
  including_tax: 'inkl. MwSt.',
  plus_shipping: 'zzgl. Versand',
  including_tax_plus_shipping: 'inkl. MwSt. zzgl. Versand',
  change_variant: 'Variante ändern',
  change_product: 'Produkt wechseln',
  choose_product: 'Produkt wählen',
  text_align: 'Text ausrichten',
  text_align_left: 'Text linksbündig ausrichten',
  text_align_center: 'Text mittig ausrichten',
  text_align_right: 'Text rechtsbündig ausrichten',
  text_decoration: 'Dekoration',
  text_bold: 'Fettschrift',
  text_italic: 'Kursiv',
  text_underline: 'Unterstrichen',
  printtype: 'Druckart',
  change_printtype: 'Druckart ändern',
  choose_printtype: 'Druckart wählen',
  color_s: 'Farbe(n)',
  clone_object: 'Objekt duplizieren',
  remove_object: 'Objekt entfernen',
  delete_object: 'Entfernen',
  deselect_object: 'Abwählen',
  file: 'Datei',
  add_image: 'Bild hinzufügen',
  upload_terms_headline: 'Hinweis zu Rechten Dritter',
  product_description: 'Produktbeschreibung',
  upload_terms: 'Mit Hochladen eines Bildes bestätigst Du, dass dieses weder gegen geltende Gesetze verstößt noch Rechte Dritter verletzt.',
  accept_upload_terms: 'Akzeptieren',
  zoom: 'Zoom',
  choose_image_or_drop_here: 'Bild %{browse} oder hierher ziehen',
  browse_image: 'hochladen',
  current_variant: 'Produktfarbe:',
  amount: 'Menge',
  template_controls: 'Vorlage',
  template_product: 'Produkteinstellungen',
  is_template: 'Vorlagenmodus aktivieren',
  minimum_amount: 'Min. Anzahl',
  maximum_amount: 'Max. Anzahl',
  package_unit: 'VPE',
  price: 'Preis',
  fix_price: 'Preis fixieren',
  product_title: 'Titel',
  settings: 'Funktionen',
  allow_change_product: 'Produkt ändern',
  allow_change_variant: 'Variante ändern',
  allow_change_size: 'Größe ändern',
  allow_change_printtype: 'Druckart ändern',
  show_toolbar: 'Toolbar anzeigen',
  toolbar_label: 'Objekt bearbeiten',
  allow_add_text: 'Texte einfg.',
  allow_add_logo: 'Motive  einfg.',
  allow_add_upload: 'Uploads  einfg.',
  allow_add_custom: 'Spez.Obj.  einfg.',
  printtype_mode: 'Druckart Modus',
  allowed_printtypes: 'Erlaubte Druckarten',
  allowed_logo_categories: 'Erlaubte Motivkategorien',
  per_object: 'Pro Objekt',
  per_view: 'Pro Ansicht',
  per_product: 'Pro Produkt',
  object_template: 'Objekt Funktionen',
  allow_scaling: 'Skalieren',
  clonable: 'Duplizieren',
  allow_rotation: 'Rotieren',
  allow_move_x: 'Horizontal bewegen',
  allow_move_y: 'Vertikal bewegen',
  allow_change_layer: 'Ebene ändern',
  show_controls: 'Funktionsecken',
  show_borders: 'Rahmen',
  colorizable: 'Einfärben',
  removable: 'Entfernen',
  toggle_template_mode: 'Vorlage testen',
  untoggle_template_mode: 'Test beenden',
  user_logo_object_template: 'Upload Funktionen',
  allow_remove_white: 'Weißen Hintergrund entfernen',
  text_object_template: 'Text Funktionen',
  allow_change_content: 'Inhalt ändern',
  allow_change_font: 'Schriftart ändern',
  allow_change_font_weight: 'Fettschrift ändern',
  allow_change_font_style: 'Kursivschrift ändern',
  allow_change_text_align: 'Ausrichtung ändern',
  identifier: 'Ident.',
  label: 'Titel',
  object_template_info: 'Fett markierte Einträge überschreiben die Einstellungen des Produkts',
  show_price: 'Preis berechnen',
  product_infos: 'Gewähltes Produkt',
  logo_infos: 'Gewähltes Motiv',
  currently_selected: 'Derzeit ausgewählt',
  change_size: 'Größe ändern',
  choose_variant: 'Variante auswählen',
  change_logo: 'Motiv wechseln',
  enter_text: 'Text eingeben',
  enter_more_text: '... weiteren Text eingeben',
  change_printtype_color: 'Farben/Druckart ändern',
  colors: 'Farben',
  confirm: 'OK',
  change_font: 'Schriftart ändern',
  price_per_unit: 'Artikelpreis',
  price_details: 'Details',
  item_quantity: 'Artikel ausgewählt',
  full_price: 'Gesamt',
  edit_object: 'Bearbeiten',
  variant: 'Variante',
  print_cost: 'Druckkosten',
  sizes: 'Größen',
  designs: 'Gestaltung',
  upload_special_price: 'Einrichtungskosten',
  upload_special_price_info: 'Die Einrichtungskosten werden nur einmalig (pro Upload) berechnet, egal wie viele Artikel bestellt werden.',
  cart_after_sales: 'Produkt im Warenkorb',
  preview: 'Vorschau',
  in_stock: 'Auf Lager',
  not_in_stock: 'Nicht auf Lager'

}
