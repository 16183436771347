import store from '../store/'
import get from 'lodash.get'

export default {
  install (Vue, options) {
    Vue.prototype.$get = function (variable) {
      return get(store.getters, variable)
    }
    Vue.prototype.$local = function (variable) {
      return get(store.getters.localVars, variable)
    }
    Vue.prototype.$shop = function (variable) {
      return get(store.getters.localVars, 'shopProductInfos.' + variable)
    }
    Vue.prototype.$t = Vue.$t = function (key, ...values) {
      return store.getters.translation(key)
    }
  }
}
