// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import store from './store'
import viewhelper from './plugins/viewhelper'
import semverSatisfies from 'semver/functions/satisfies'

import ShirtnetworkDesigner from './ShirtnetworkDesigner'

if (process.env.NODE_ENV !== 'production') {
  const debug = require('debug')
  debug.enable('shirtnetwork:*')
}

Vue.config.productionTip = false

// Allow components to define a templateOverride variable
const mount = Vue.prototype.$mount

Vue.prototype.$mount = function (el, hydrating) {
  const options = this.$options
  const override = options.override ? document.querySelector('[data-override="' + options.override + '"]') : null
  if (override && override.dataset.version) {
    if (semverSatisfies(process.env.VUE_APP_VERSION, override.dataset.version)) {
      const renderFunctions = Vue.compile(override.innerHTML)
      Object.assign(options, renderFunctions)
    } else {
      console.log('version mismatch for', options.override, process.env.VUE_APP_VERSION)
    }
  }
  return mount.call(this, el, hydrating)
}

Vue.use(viewhelper)

/* eslint-disable no-new */
const ShirtnetworkDesignerApp = Vue.extend({
  render: h => h(ShirtnetworkDesigner),
  store
})
export default ShirtnetworkDesignerApp
