import UppyManager from '../managers/UppyManager'

// initial state
const state = {
  all: [],
  selected: undefined,
  progress: undefined,
  uppyInstances: []
}

// getters
const getters = {
  allUploads: state => state.all,
  selectedUpload: state => state.selected,
  uploadProgress: state => state.progress,
  uppy: state => (id) => UppyManager.getUppy(id)
}

// actions
const actions = {
  setSelectedUpload ({ commit, dispatch }, upload) {
    commit('setSelectedUpload', upload)
    return dispatch('addUserLogo', { url: upload.uploadURL, name: upload.name })
  },
  addUpload ({ commit }, upload) {
    commit('addUpload', upload)
  },
  updateUpload ({ commit }, upload) {
    commit('updateUpload', upload)
  },
  setUploadProgress ({ commit }, progress) {
    commit('setUploadProgress', progress)
  },
  async createUppy ({ commit, state, rootGetters, dispatch }, id) {
    if (!UppyManager.getUppy(id)) {
      const Uppy = await import('@uppy/core').then(m => m.default)
      var uppy = Uppy({
        id: id,
        autoProceed: true,
        debug: false,
        restrictions: {
          maxFileSize: false,
          allowedFileTypes: ['image/*', 'application/pdf', 'application/postscript']
        }
      })
      // uppy.use(FileInput, { target: '#' + id + '_input', pretty: true })
      const Tus = await import('@uppy/tus').then(m => m.default)
      uppy.use(Tus, {
        endpoint: rootGetters.uploadServer,
        resume: true,
        autoRetry: true,
        retryDelays: [0, 1000, 3000, 5000]
      })
      uppy.on('upload-progress', (file, progress) => {
        dispatch('setUploadProgress', { id: file.id, name: file.name, bytesTotal: progress.bytesTotal, bytesUploaded: progress.bytesUploaded })
      })
      uppy.on('file-added', (file) => {
        dispatch('addUpload', file)
      })
      uppy.on('complete', (result) => {
        result.successful.forEach((f) => {
          dispatch('setUploadProgress', undefined)
          dispatch('updateUpload', f)
          dispatch('setSelectedUpload', f)
        })
      })
      uppy.on('upload', (file) => {
        console.log('upload')
      })
      UppyManager.addUppy(uppy)
    }

    return UppyManager.getUppy(id)
  }
}

// mutations
const mutations = {
  setSelectedUpload (state, upload) {
    state.selected = upload
  },
  addUpload (state, upload) {
    state.all.push(upload)
  },
  updateUpload (state, upload) {
    const currentUpload = state.all.find(u => u.id === upload.id)
    if (upload) {
      Object.assign(currentUpload, upload)
    }
  },
  setUploadProgress (state, progress) {
    state.progress = progress
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
