import FabricManager from '../managers/FabricManager'
import Vue from 'vue'
const debug = require('debug')('shirtnetwork:store:objects')

// initial state
const state = {
  all: [],
  selected: [],
  allSelected: [],
  history: [],
  historyIndex: 0,
  isMouseDown: false,
  canvasOffset: {
    left: 0,
    top: 0
  },
  zoom: 1,
  useConstraints: true,
  enableInlineTextEditing: false,
  shareLink: undefined,
  swapObjects: false,
  showRealSizes: true,
  constrainToRealSize: true,
  zoomToConstraints: 'mobile'
}

// getters
const getters = {
  objects: state => state.all,
  selectedViewObjects: (state, getters, rootState, rootGetters) => getters.viewObjects(rootGetters.selectedVariantView),
  viewObjects: state => view => view ? state.all.filter((o) => {
    return o.meta.view && o.meta.view.id === view.id
  }) : [],
  objectById: (state, getters) => (id) => getters.objects.find((o) => {
    return o.id === id
  }),
  objectsByType: state => type => state.all.filter((o) => o.type === type),
  viewObjectsByType: (state, getters) => (view, type) => getters.viewObjects(view).filter((o) => o.type === type),
  selectedObject: state => state.selected.length > 0 ? state.selected[0] : undefined,
  selectedObjects: state => state.selected,
  swapObjectContents: state => state.swapObjectContents,
  isMouseDown: state => state.isMouseDown,
  showRealSizes: state => state.showRealSizes,
  showToolbar: (state, getters, rootState, rootGetters) => rootGetters.useTemplate && rootGetters.template ? rootGetters.template.settings.showToolbar : true,
  canvasOffset: state => state.canvasOffset,
  useConstraints: state => state.useConstraints,
  constrainToRealSize: state => state.constrainToRealSize,
  shareLink: state => state.shareLink,
  enableInlineTextEditing: state => state.enableInlineTextEditing,
  selectedObjectView: (state, getters, rootState, rootGetters) => getters.selectedObject ? getters.selectedObject.meta.view : rootGetters.selectedVariantView,
  fill: (state, getters) => getters.selectedObject && getters.fills && getters.fillIndex ? getters.fills[getters.fillIndex] : undefined,
  fills: (state, getters) => getters.selectedObject ? getters.selectedObject.options.fills : [],
  fillIndex: (state, getters) => getters.selectedObject ? getters.selectedObject.options.fillIndex : 0,
  filters: (state, getters) => getters.selectedObject ? getters.selectedObject.options.filters : [],
  selectedObjectDimensions: (state, getters) => getters.selectedObject ? getters.objectDimensions(getters.selectedObject) : false,
  textLines: (state, getters) => getters.selectedObject && getters.selectedObject.type === 'text' ? getters.selectedObject.options.text.split(/\r?\n/) : [],
  objectDimensions: state => (object) => {
    const view = object.meta.view
    const fw = view.width / view.realWidth
    const fh = view.height / view.realHeight

    // These are the real cm sizes of the object then
    const options = object.options
    const trw = options.width * options.scaleX / fw // cm width
    const trh = options.height * options.scaleY / fh // cm height
    const cm2 = trw * trh
    const dpiX = (options.width * 2.54) / trw
    const dpiY = (options.height * 2.54) / trh

    return { width: trw, height: trh, size: cm2, dpi: Math.min(dpiX, dpiY) }
  },
  objectConstraints: (state, getters, rootState, rootGetters) => (object) => {
    const view = object.meta.view
    const sX = getters.constrainToRealSize && view.realWidth ? view.realWidth / view.width : 1
    const sY = getters.constrainToRealSize && view.realHeight ? view.realHeight / view.height : 1
    const numLines = object.options.text ? object.options.text.split(/\r?\n/).length : 1
    switch (object.type) {
      case 'logo': {
        const logo = object.meta.logo
        return {
          minWidth: logo.minwidth / sX,
          minHeight: logo.minheight / sY,
          maxWidth: logo.maxwidth / sX,
          maxHeight: logo.maxheight / sY
        }
      }
      case 'text':
        return {
          minWidth: rootGetters.selectedProduct.minTextWidth / sX,
          minHeight: rootGetters.selectedProduct.minTextHeight * numLines / sY,
          maxWidth: rootGetters.selectedProduct.maxTextWidth / sX,
          maxHeight: rootGetters.selectedProduct.maxTextHeight * numLines / sY
        }
      case 'user-logo':
        return {
          minWidth: rootGetters.selectedProduct.minUploadWidth / sX,
          minHeight: rootGetters.selectedProduct.minUploadHeight / sY,
          maxWidth: rootGetters.selectedProduct.maxUploadWidth / sX,
          maxHeight: rootGetters.selectedProduct.maxUploadHeight / sY
        }
      default:
        return {}
    }
  },
  objectTemplateProperties: (state, getters, rootState, rootGetters) => (object) => {
    const template = rootGetters.useTemplate && object.template ? object.template : {}

    return {
      hasControls: template.showControls !== false,
      hasBorders: template.borders !== false,
      lockMovementX: template.movableX === false,
      lockMovementY: template.movableY === false,
      lockScalingX: template.scalable === false,
      lockScalingY: template.scalable === false,
      lockRotation: template.rotatable === false,
      fillable: template.colorizable !== false,
      changeable: template.changeContent !== false,
      layerable: template.changeLayer !== false,
      _controlsVisibility: {
        tl: template.removable !== false,
        tr: template.rotatable !== false,
        br: template.scalable !== false,
        bl: template.movableX !== false || template.movableY !== false,
        ml: false,
        mt: false,
        mr: false,
        mb: false,
        mtr: false
      }
    }
  }

}

// actions
const actions = {
  async addLogo ({ rootGetters, dispatch }, logo) {
    return dispatch('addObject', {
      type: 'logo',
      options: {
        fillable: logo.colorize === 1,
        lockScalingX: logo.scalable !== 1,
        lockScalingY: logo.scalable !== 1,
        lockRotation: logo.scalable !== 1
      },
      meta: {
        view: rootGetters.selectedVariantView,
        printtype: undefined,
        logo: logo
      },
      template: {}
    })
  },
  async addText ({ rootGetters, dispatch }, { text, options, meta }) {
    debug('addText')
    const printtype = rootGetters.initialObjectPrinttype({ object: { type: 'text', options: {}, meta: {} } })
    const colors = rootGetters.printtypeColors(printtype)
    debug('addObject follows ...')
    return dispatch('addObject', {
      type: 'text',
      options: Object.assign({
        fontFamily: rootGetters.selectedFont ? rootGetters.selectedFont.title : '',
        editable: rootGetters.enableInlineTextEditing,
        fill: colors.length ? colors[0].hex : '#000000',
        textAlign: 'center'
      }, options || {}),
      meta: Object.assign({
        view: rootGetters.selectedVariantView,
        printtype: undefined,
        text: text
      }, meta || {}),
      template: {}
    })
  },
  async addUserLogo ({ rootGetters, dispatch }, { url, name }) {
    return dispatch('addObject', {
      type: 'user-logo',
      options: {},
      meta: {
        view: rootGetters.selectedVariantView,
        printtype: undefined,
        original: url,
        url: url.replace('/files/', '/preview/'),
        name: name
      },
      template: {}
    })
  },
  async addCustomObject ({ rootGetters, dispatch }, { type, options }) {
    return dispatch('addObject', {
      type: type,
      options: options,
      meta: {
        view: rootGetters.selectedVariantView,
        printtype: undefined
      },
      template: {}
    })
  },
  async addObject ({ rootGetters, dispatch, commit }, o) {
    debug('addObject', o)
    const fabricInstance = await FabricManager.getFabric(o.meta.view)
    debug('got instance', o)
    // Check if object is allowed before adding, the messages will be triggered in the check function
    const objectAllowed = await dispatch('checkObjectAllowed', o)
    if (!objectAllowed) {
      return
    }

    commit('addObject', o)

    // Create a non reactive copy of the options before passing to fabric, otherwise vuex will complain
    const options = JSON.parse(JSON.stringify(o.options))
    const offset = o.meta.offset ? JSON.parse(JSON.stringify(o.meta.offset)) : null

    debug('adding at', options.left, options.top)

    switch (o.type) {
      case 'logo':
        return rootGetters.user.then(user => {
          return fabricInstance.addSVGLogo(rootGetters.apiUrl + '/out/logos/' + user + '/' + (o.meta.logo.svg || o.meta.logo.picture), options, offset).then(({ uid, shape }) => {
            debug('object created', shape.left, shape.top)
            return dispatch('objectCreated', { object: o, uid: uid, shape: shape })
          }).catch(error => {
            debug('failed adding object', error)
            commit('removeObject', o)
          })
        })
      case 'text':
        return fabricInstance.addText(o.meta.text, options, offset).then(({ uid, shape }) => {
          return dispatch('objectCreated', { object: o, uid: uid, shape: shape })
        }).catch(error => {
          debug('failed adding object', error)
          commit('removeObject', o)
        })
      case 'user-logo':
        return fabricInstance.addUserLogo(o.meta.url, options, offset).then(({ uid, shape }) => {
          return dispatch('objectCreated', { object: o, uid: uid, shape: shape })
        }).catch(error => {
          debug('failed adding object', error)
          commit('removeObject', o)
        })
      default:
        return fabricInstance.addObject(o.type, options, offset).then(({ uid, shape }) => {
          return dispatch('objectCreated', { object: o, uid: uid, shape: shape })
        }).catch(error => {
          debug('failed adding object', error)
          commit('removeObject', o)
        })
    }
  },
  checkObjectAllowed ({ getters, rootGetters, dispatch }, o) {
    // Is this a displayOnly view?
    if (o.meta.view.displayOnly) {
      if (o.meta.wasRestored === true) {
        dispatch('addMessage', { message: Vue.$t('Es konnten nicht alle gestalteten Objekte auf das neue Produkt übernommen werden.'), options: { title: Vue.$t('Bitte beachten') } })
      } else {
        dispatch('addMessage', { message: Vue.$t('Diese Ansicht ist nicht gestaltbar, das Objekt wurde nicht hinzugefügt.'), options: { title: Vue.$t('Bitte beachten') } })
      }
      return false
    }

    // Uploads on variant allowed ?
    if (o.type === 'user-logo' && !rootGetters.selectedVariant.uploadEnabled) {
      dispatch('addMessage', { message: Vue.$t('Auf dieser Variante können keine Bilder hochgeladen werden, das Bild wurde entfernt.'), options: { title: Vue.$t('Bitte beachten') } })
      return false
    }

    // Uploads on this view allowed ?
    if (o.type === 'user-logo' && rootGetters.selectedVariantView.disableUpload) {
      dispatch('addMessage', { message: Vue.$t('Auf dieser Ansicht können keine Bilder hochgeladen werden, das Bild wurde entfernt.'), options: { title: Vue.$t('Bitte beachten') } })
      return false
    }

    // Exceeds maxTextBlocks allowed?
    if (rootGetters.selectedProduct.maxTextBlocks >= 0 && o.type === 'text') {
      const currentTextBlocks = rootGetters.selectedProduct.constraintsPerProduct ? getters.objectsByType('text') : getters.viewObjectsByType(o.meta.view, 'text')
      if (currentTextBlocks.length >= rootGetters.selectedProduct.maxTextBlocks) {
        dispatch('addMessage', { message: Vue.$t('Es können keine weiteren Textblöcke erstellt werden.'), options: { title: Vue.$t('Bitte beachten') } })
        return false
      }
    }

    // Is allowed logo and at least one printtype matches
    if (o.type === 'logo' && (!rootGetters.isAllowedLogo(o.meta.logo) || !rootGetters.initialObjectPrinttype({ object: o }))) {
      if (o.meta.wasRestored === true) {
        dispatch('addMessage', { message: Vue.$t('Es konnten nicht alle gestalteten Objekte auf das neue Produkt übernommen werden.'), options: { title: Vue.$t('Bitte beachten') } })
      } else {
        dispatch('addMessage', { message: Vue.$t('Dieses Motiv ist auf diesem Produkt nicht verfügbar.'), options: { title: Vue.$t('Bitte beachten') } })
      }
      return false
    }

    // Exceeds maxLogos allowed?
    if (rootGetters.selectedProduct.maxLogos >= 0 && o.type === 'logo') {
      const currentLogos = rootGetters.selectedProduct.constraintsPerProduct ? getters.objectsByType('logo') : getters.viewObjectsByType(o.meta.view, 'logo')
      if (currentLogos.length >= rootGetters.selectedProduct.maxLogos) {
        dispatch('addMessage', { message: Vue.$t('Es können keine weiteren Motive hinzugefügt werden.'), options: { title: Vue.$t('Bitte beachten') } })
        return false
      }
    }

    return true
  },
  async cloneObject ({ dispatch }, object) {
    const cloned = JSON.parse(JSON.stringify(object))
    cloned.id = undefined
    cloned.options.left = cloned.options.left + 10
    cloned.options.top = cloned.options.top + 10
    cloned.meta.wasRestored = false
    return dispatch('addObject', cloned)
  },
  async objectCreated ({ commit, dispatch }, { object, uid, shape }) {
    commit('setObjectId', { object: object, id: uid })
    await dispatch('initializeObjectPrinttype', object)
    await dispatch('initializeShape', shape)
    return dispatch('checkOtherObjectPrinttypes', object)
  },
  async initializeShape ({ commit, getters, rootGetters, dispatch }, shape) {
    const object = getters.objectById(shape.$uid)
    const fabric = await FabricManager.getFabric(object.meta.view)
    const constraints = getters.objectConstraints(object)

    fabric.setObjectProperties(constraints, shape)
    !object.meta.wasRestored && fabric.setActiveObject(shape)
    return object
  },
  async initializeObjectPrinttype ({ commit, getters, rootGetters, dispatch }, object) {
    const fabric = await FabricManager.getFabric(object.meta.view)
    const shape = fabric.getObjectByUID(object.id)
    const printtype = rootGetters.initialObjectPrinttype({ object })
    dispatch('setObjectMeta', { object: object, meta: Object.assign({}, object.meta, { printtype: printtype }) })

    const fills = shape.fills ? shape.fills : [shape.fill]

    fabric.setObjectProperties({
      fills: rootGetters.matchingFills(fills, rootGetters.printtypeColors(printtype)),
      fontFamily: shape.fontFamily ? rootGetters.matchingFontFamily(shape.fontFamily, object.meta.printtype) : undefined
    }, shape)

    return object
  },
  async initializeObject ({ dispatch }, object) {
    if (object.meta.view) {
      const fabric = await FabricManager.getFabric(object.meta.view)
      dispatch('initializeObjectPrinttype', object)
      dispatch('initializeShape', fabric.getObjectByUID(object.id))
    }
  },
  async initializeLineFills ({ rootGetters, getters }, object) {
    const fabric = await FabricManager.getFabric(object.meta.view)
    const shape = fabric.getObjectByUID(object.id)
    if (shape.fills.length < shape.textLines.length) {
      // Create an array with the missing lines, containing always the last set fill
      const addFills = Array(shape.textLines.length - shape.fills.length).fill(shape.fills[shape.fills.length - 1])
      fabric.setObjectProperties({ fills: shape.fills.concat(addFills) }, shape)
    } else if (shape.fills.length > shape.textLines.length) {
      // Remove unused line fills at the end
      fabric.setObjectProperties({ fills: shape.fills.slice(0, shape.textLines.length) }, shape)
    }
    // We just update the constraints for more lines here, maybe move this
    const constraints = getters.objectConstraints(object)
    fabric.setObjectProperties(constraints, shape)
  },
  setObjectOptions ({ commit }, { object, options }) {
    commit('setObjectOptions', { object, options })
  },
  setObjectMeta ({ commit }, { object, meta }) {
    commit('setObjectMeta', { object, meta })
  },
  setObjectTemplateProperty ({ commit }, { object, property, value }) {
    commit('setObjectTemplateProperty', { object, property, value })
  },
  setSwapObjects ({ commit }, swapObjects) {
    commit('setSwapObjects', swapObjects)
  },
  async setObjectDimensions ({ getters }, dimensions) {
    const view = getters.selectedObject.meta.view
    const fw = view.width / view.realWidth
    const fh = view.height / view.realHeight

    debug('setObjectDimensions', dimensions)
    const fabric = await FabricManager.getFabric(view)

    if (dimensions.setBy === 'width') {
      fabric.setObjectWidth(dimensions.width * fw)
    } else {
      fabric.setObjectHeight(dimensions.height * fh)
    }
  },
  setSelectedObject ({ commit }, object) {
    commit('setSelectedObjects', object ? [object] : [])
  },
  setSelectedObjects ({ commit }, objects) {
    commit('setSelectedObjects', objects)
  },
  async selectObject ({ rootGetters, dispatch }, object) {
    const fabricInstance = await FabricManager.getFabric(rootGetters.selectedVariantView)
    fabricInstance.setActiveObject(fabricInstance.getObjectByUID(object.id))
  },
  async discardActiveObject ({ rootGetters, dispatch }) {
    const fabricInstance = await FabricManager.getFabric(rootGetters.selectedVariantView)
    fabricInstance.discardActiveObject()
    dispatch('setSelectedObject', undefined)
  },
  async restoreObjects ({ commit, dispatch, getters }, { newView, oldView }) {
    const objects = getters.viewObjects(oldView)
    debug('restoreObjects', objects, oldView.id, newView.id, oldView.key, newView.key)
    for (const i in objects) {
      commit('setObjectMeta', { object: objects[i], meta: Object.assign({}, objects[i].meta, { view: newView, wasRestored: true }) })
    }
    debug('restoring objects ...')
    for (const o of objects) {
      debug('remove object before restore ', o)
      // must remove before add or the checkObjectAllowed function will not work correctly
      dispatch('removeObject', o)
      debug('restore object ', o)
      await dispatch('addObject', o)
    }
    debug('finished restoring')
  },
  removeObject ({ commit }, object) {
    commit('removeObject', object)
  },
  async applyObjectTemplates ({ getters }) {
    const objects = getters.objects
    for (const o of objects) {
      const fabric = await FabricManager.getFabric(o.meta.view)
      fabric.setObjectProperties(getters.objectTemplateProperties(o), fabric.getObjectByUID(o.id))
    }
  },
  async removeObjectFromFabric ({ commit }, object) {
    // commit('removeObject', object) // -> will be triggered by event handlers
    const fabric = await FabricManager.getFabric(object.meta.view)
    if (fabric) {
      fabric.removeObject(fabric.getObjectByUID(object.id))
    }
  },
  async removeAllObjectsFromFabric ({ dispatch, getters }) {
    getters.objects.forEach(o => dispatch('removeObjectFromFabric', o))
  },
  async setSelectedVariantView ({ rootGetters }, view) {
    const fabric = await FabricManager.getFabric(view)
    if (fabric) {
      // fabric.setCanvasZoom(1)
    }
  },
  async setCanvasZoom ({ rootGetters, commit }, zoom) {
    if (rootGetters.selectedVariantView) {
      const fabric = await FabricManager.getFabric(rootGetters.selectedVariantView)
      fabric.setCanvasZoom(zoom)
      commit('setCanvasZoom', zoom)
    }
  },
  async setCanvasBackground ({ rootGetters }, bg) {
    if (rootGetters.selectedVariantView) {
      const fabric = await FabricManager.getFabric(rootGetters.selectedVariantView)
      fabric.setCanvasBackground(bg)
    }
  },
  async setObjectProperties ({ getters, rootGetters }, properties) {
    const view = getters.selectedObjectView
    if (view) {
      const fabric = await FabricManager.getFabric(view)
      fabric.setObjectProperties(properties)
    }
  },
  async toggleObjectProperties ({ commit, getters }, properties) {
    var obj = getters.selectedObject
    if (obj) {
      const toggledProperties = {}
      for (const [key, value] of Object.entries(properties)) {
        if (obj.options[key] === value[0]) {
          toggledProperties[key] = value[1]
        } else {
          toggledProperties[key] = value[0]
        }
      }
      const fabric = await FabricManager.getFabric(getters.selectedObject.meta.view)
      fabric.setObjectProperties(toggledProperties)
    }
  },
  async toggleImageFilter ({ dispatch, getters }, { filter, properties }) {
    var obj = getters.selectedObject
    if (obj) {
      if (obj.options.filters.findIndex(f => f.type === filter) !== -1) {
        return dispatch('removeImageFilter', filter)
      } else {
        return dispatch('addImageFilter', { filter, properties })
      }
    }
  },
  async addImageFilter ({ commit, getters }, { filter, properties }) {
    var obj = getters.selectedObject
    if (obj) {
      const fabric = await FabricManager.getFabric(getters.selectedObject.meta.view)
      fabric.addObjectFilter(filter, properties)
    }
  },
  async removeImageFilter ({ commit, getters }, filter) {
    var obj = getters.selectedObject
    if (obj) {
      const fabric = await FabricManager.getFabric(getters.selectedObject.meta.view)
      fabric.removeObjectFilter(filter)
    }
  },
  async setObjectAlign ({ getters, rootGetters }, align) {
    const view = getters.selectedObjectView
    if (view) {
      const fabric = await FabricManager.getFabric(view)
      fabric.setObjectAlign(align)
    }
  },
  async setObjectLayer ({ getters, rootGetters }, layer) {
    const view = getters.selectedObjectView
    if (view) {
      const fabric = await FabricManager.getFabric(view)
      fabric.setObjectLayer(layer)
    }
  },
  async executeObjectFunction ({ getters, rootGetters }, { fnc, args }) {
    const view = getters.selectedObjectView
    if (view) {
      const fabric = await FabricManager.getFabric(view)
      fabric.executeObjectFunction(fnc, args)
    }
  },
  async getSelectedObjectPreview ({ getters }, multiplier) {
    const object = getters.selectedObject
    if (object) {
      const view = object.meta.view
      if (view) {
        const fabric = await FabricManager.getFabric(view)
        return fabric.getObjectDataUrl(multiplier)
      }
    }
  },
  setIsMouseDown ({ commit }, isMouseDown) {
    commit('setIsMouseDown', isMouseDown)
  },
  setCanvasOffset ({ commit }, canvasOffset) {
    commit('setCanvasOffset', canvasOffset)
  },
  setUseConstraints ({ commit }, useConstraints) {
    commit('setUseConstraints', useConstraints)
  },
  setEnableInlineTextEditing ({ commit }, enableInlineTextEditing) {
    commit('setEnableInlineTextEditing', enableInlineTextEditing)
  },
  setShareLink ({ commit }, shareLink) {
    commit('setShareLink', shareLink)
    if (process.env.NODE_ENV !== 'production') {
      setTimeout(function () {
        commit('setShareLink', 'https://www.example.org/my/config/foo')
      }, 2000)
    }
  },
  setShowRealSizes ({ commit }, showRealSizes) {
    commit('setShowRealSizes', showRealSizes)
  }
}

// mutations
const mutations = {
  addObject (state, object) {
    const obj = state.all.find((o) => {
      return o === object
    })
    if (!obj) {
      state.all.push(object)
    }
  },
  setObjectId (state, { object, id }) {
    const obj = state.all.find((o) => { return o === object })
    if (obj) obj.id = id
  },
  setObjectOptions (state, { object, options }) {
    const obj = state.all.find((o) => { return o === object })
    if (obj) obj.options = options
  },
  setObjectMeta (state, { object, meta }) {
    const obj = state.all.find((o) => {
      return o.id === object.id
    })
    if (obj) obj.meta = meta
  },
  setObjectTemplateProperty (state, { object, property, value }) {
    const obj = state.all.find((o) => {
      return o.id === object.id
    })
    if (obj) obj.template[property] = value
  },
  setSwapObjects (state, swapObjects) {
    state.swapObjects = swapObjects
  },
  setSelectedObjects (state, selected) {
    state.selected = selected
  },
  removeObject (state, object) {
    if (state.all.indexOf(object) !== -1) { state.all.splice(state.all.indexOf(object), 1) }
  },
  setIsMouseDown (state, isMouseDown) {
    state.isMouseDown = isMouseDown
  },
  setCanvasOffset (state, canvasOffset) {
    state.canvasOffset = Object.assign({}, canvasOffset)
  },
  setCanvasZoom (state, zoom) {
    state.zoom = zoom
  },
  setUseConstraints (state, useConstraints) {
    state.useConstraints = useConstraints
  },
  setEnableInlineTextEditing (state, enableInlineTextEditing) {
    state.enableInlineTextEditing = enableInlineTextEditing
  },
  setShareLink (state, shareLink) {
    state.shareLink = shareLink
  },
  setShowRealSizes (state, showRealSizes) {
    state.showRealSizes = showRealSizes
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
