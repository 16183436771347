import 'whatwg-fetch'
const API_URL = '//api.shirtnetwork.de/rest/'

// simple local cache implementation
const cache = {
  data: [],
  get: function (key) {
    return this.data[key]
  },
  set: function (key, value) {
    this.data[key] = value
  }
}

const getRequest = function (url) {
  const _cached = cache.get(url)

  if (_cached) {
    return Promise.resolve(_cached)
  }

  return fetch(url)
    .then(r => r.json()) // convert to json
    .then(data => { // cache
      cache.set(url, data)
      return data
    })
}
/*
// auto cache get requests
api.interceptors.request.use(request => {
  // Only cache GET requests
  if (request.method === 'get') {
    let url = request.url

    // Append the params
    if (request.params) { url += '?' + JSON.stringify(request.params) }

    const _cached = cache.get(url)

    if (_cached) {
      request.data = _cached

      // Set the request adapter to send the cached response and prevent the request from actually running
      request.adapter = () => {
        return Promise.resolve({
          data: _cached,
          status: request.status,
          statusText: request.statusText,
          headers: request.headers,
          config: request,
          request: request
        })
      }
    }
  }

  return request
}, error => Promise.reject(error))

// this will force our promise to return the data directly
// otherwise we would have to handle the response in our store
api.interceptors.response.use(function (response) {
  let url = response.config.url

  if (response.config.params) { url += '?' + JSON.stringify(response.config.params) }

  if (response.config.method === 'get') {
    // On get request, store the response in the cache
    cache.set(url, response.data)
  }

  return response.data
}, function (error) {
  return Promise.reject(error)
})
*/

export default {
  cacheKey: Date.now(),
  setCacheKey (key) {
    this.cacheKey = key
  },
  getProductCount (user, category, filter) {
    const filterQuery = filter && filter.length ? '?filter=' + filter.join('|') : ''
    return getRequest(this.getRequestUrl('productcount/' + user + '/' + category.id + filterQuery))
  },
  getProducts (user, category, filter, numItems, page) {
    const filterQuery = filter && filter.length ? '?filter=' + filter.join('|') : ''
    return getRequest(this.getRequestUrl('products/' + user + '/' + category.id + '/' + numItems + '/' + (page - 1) + filterQuery))
  },
  hasCoins (user) {
    return getRequest(this.getRequestUrl('hascoins/' + user))
  },
  getProduct (product) {
    return getRequest(this.getRequestUrl('product/' + product.id))
  },
  getProductBySku (user, sku) {
    return getRequest(this.getRequestUrl('productbysku/' + user + '/' + sku))
  },
  getProductFilter (user, category) {
    return getRequest(this.getRequestUrl('productfilter/' + user + '/' + category.id))
  },
  getVariants (product) {
    return getRequest(this.getRequestUrl('variants/' + product.id))
  },
  getVariant (variant) {
    return getRequest(this.getRequestUrl('variant/' + variant.id))
  },
  getVariantBySku (user, productsku, variantsku) {
    return getRequest(this.getRequestUrl('variantbysku/' + user + '/' + productsku + '/' + variantsku))
  },
  getCategories (user) {
    return getRequest(this.getRequestUrl('categories/' + user))
  },
  getLogoCategories (user) {
    return getRequest(this.getRequestUrl('logocategories/' + user))
  },
  getLogoCount (user, category) {
    return getRequest(this.getRequestUrl('logocount/' + user + '/' + category.id))
  },
  getLogos (user, category, numItems, page) {
    return getRequest(this.getRequestUrl('logos/' + user + '/' + category.id + '/' + numItems + '/' + (page - 1)))
  },
  getLogo (logo) {
    return getRequest(this.getRequestUrl('logo/' + logo.id))
  },
  getPrinttypes (user) {
    return getRequest(this.getRequestUrl('printtypes/' + user))
  },
  getRequestUrl (path) {
    const appendix = path.indexOf('?') === -1 ? '?' : '&'
    return API_URL + path + appendix + '__ts=' + this.cacheKey
  }
}
