import colorTools from '@/lib/color'

/**
 * @namespace
 * @property {object}  state                  - Printtype vuex state.
 * @property {object}  state.selected         - Currently selected printtype.
 * @property {string}  state.printtypeMode    - Which printtype mode to use, allowed values are object|view|product.
 */
const state = {
  selected: undefined,
  printtypeMode: 'view'
}

// getters
const getters = {
  printtypeMode: (state, getters, rootState, rootGetters) => rootGetters.useTemplate && rootGetters.template && rootGetters.template.printtype.mode ? rootGetters.template.printtype.mode : state.printtypeMode,
  printtypeById: (state, getters, rootState, rootGetters) => (id) => rootGetters.selectedProduct.printtypes.source.find(p => p.id === id),
  allProductPrinttypes: (state, getters, rootState, rootGetters) => rootGetters.selectedProduct ? rootGetters.selectedProduct.printtypes.source.slice(0) : [],
  productPrinttypes: (state, getters) => getters.templatePrinttypes,
  templatePrinttypes: (state, getters, rootState, rootGetters) => {
    if (rootGetters.useTemplate && rootGetters.template && rootGetters.template.printtype.allowed.length) {
      return getters.allProductPrinttypes.filter(p => rootGetters.template.printtype.allowed.indexOf(p.id) !== -1)
    } else {
      return getters.allProductPrinttypes
    }
  },
  logoPrinttypes: (state, getters, rootState, rootGetters) => (logo) => {
    const productPrinttypes = getters.productPrinttypes
    const logoPrinttypes = Array.isArray(logo.printtypes) ? logo.printtypes : logo.printtypes.source.map(p => p.id)
    console.log(logoPrinttypes)
    return productPrinttypes.filter(pp => logoPrinttypes.indexOf(pp.id) !== -1)
  },
  /**
   * Get printtypes depending on given object and selected product
   */
  objectPrinttypes: (state, getters, rootState, rootGetters) => (obj) => {
    if (!rootGetters.selectedProduct) return []

    const productPrinttypes = getters.productPrinttypes
    let objectPrinttypes = productPrinttypes.slice(0)

    if (obj) {
      switch (obj.type) {
        case 'logo':
          // return intersection of product and logo printtypes
          objectPrinttypes = getters.logoPrinttypes(obj.meta.logo)
          break
        case 'text':
          // @TODO: implement noDigitalForText
          // availablePrinttypes = availablePrinttypes
          break
        case 'user-logo':
          objectPrinttypes = productPrinttypes.filter((p) => {
            return p.forUpload === 1
          })
          break
      }
    }

    return objectPrinttypes.sort((a, b) => { return a.ordering - b.ordering })
  },
  initialObjectPrinttype: (state, getters, rootState, rootGetters) => ({ object, reference }) => {
    const printtypes = rootGetters.objectPrinttypes(object)
    const selectedPrinttype = state.selected || printtypes[0]
    const targetPrinttype = object.meta.printtype || selectedPrinttype
    let printtype = printtypes.indexOf(targetPrinttype) !== -1 ? targetPrinttype : printtypes[0]

    if (getters.printtypeMode === 'view') {
      const existingsObjects = rootGetters.viewObjects(object.meta.view)
      if (existingsObjects.length > 1) {
        printtype = reference ? reference.meta.printtype : existingsObjects[0].meta.printtype
      }
    } else if (getters.printtypeMode === 'product') {
      const existingsObjects = rootGetters.objects
      if (existingsObjects.length > 1) {
        printtype = reference ? reference.meta.printtype : existingsObjects[0].meta.printtype
      }
    }

    return printtypes.indexOf(printtype) !== -1 ? printtype : printtypes[0]
  },
  /**
   * Get printtypes for currently selected object and product
   */
  currentPrinttypes: (state, getters, rootState, rootGetters) => {
    return getters.objectPrinttypes(rootGetters.selectedObject)
  },
  /**
   * Get colors of currently selected printtype
   */
  colors: (state, getters) => getters.printtypeColors(getters.selectedPrinttype),
  /**
   * Get normalized colors of given printtype
   */
  printtypeColors: (state, getters) => (printtype) => {
    if (printtype) {
      return printtype.colors.source.map(c => {
        // API uses decimal format, map to a default hex format
        const color = Object.assign({}, c)
        color.hex = '#' + Number(c.hex).toString(16).padStart(6, '0')
        return color
      }).sort((a, b) => { return a.ordering - b.ordering })
    } else {
      return []
    }
  },
  /**
   * Get selected printtype, can be selected objects printtype or user selected or first available
   */
  selectedPrinttype: (state, getters, rootState, rootGetters) => {
    if (rootGetters.selectedObject) {
      return getters.currentPrinttypes.indexOf(rootGetters.selectedObject.meta.printtype) !== -1 ? rootGetters.selectedObject.meta.printtype : getters.currentPrinttypes[0]
    } else if (getters.currentPrinttypes && getters.currentPrinttypes.length > 0) {
      return getters.currentPrinttypes.indexOf(state.selected) !== -1 ? state.selected : getters.currentPrinttypes[0]
    }
  },
  /**
   * Extract currently selected color from selected object
   */
  selectedColor: (state, getters, rootState, rootGetters) => {
    const invalidColor = { id: -1, name: 'n/a', hex: '#000000' }
    if (rootGetters.selectedObject) {
      var fill
      if (rootGetters.selectedObject.options.fills) {
        fill = rootGetters.selectedObject.options.fills[rootGetters.fillIndex >= 0 ? rootGetters.fillIndex : 0]
      } else {
        fill = rootGetters.selectedObject.options.fill
      }
      return getters.colorByFill(fill) || getters.colors[0] || invalidColor
    }
    return getters.colors ? getters.colors[0] : invalidColor
  },
  /**
   * Extract all selected colors from selected object
   */
  selectedColors: (state, getters, rootState, rootGetters) => {
    if (rootGetters.selectedObject) {
      var fills
      if (rootGetters.selectedObject.options.fills) {
        fills = rootGetters.selectedObject.options.fills
      } else {
        fills = [rootGetters.selectedObject.options.fill]
      }
      return fills.map(fill => getters.colorByFill(fill) || getters.colors[0])
    } else {
      return []
    }
  },
  /**
   * Maps a fill value to a color object of selected printtype
   */
  colorByFill: (state, getters) => fill => getters.colors.find((c) => c && c.hex === fill),

  /**
   * Maps a fill value to a color object of given printtype
   */
  colorByPrinttypeFill: (state, getters) => (printtype, fill) => getters.printtypeColors(printtype).find((c) => c && c.hex === fill),

  /**
   * Map fill array to color array
   */
  colorsByPrinttypeFills: (state, getters) => (printtype, fills) => fills.map((f) => getters.colorByPrinttypeFill(printtype, f)),

  /**
   * Find fill from current colors which matches best to given value
   */
  matchingFill: (state, getters) => (fill, colors) => {
    if (colors.length === 0) return '#000000'
    if (!fill) return colors[0].hex

    const distances = colors.map(c => colorTools.deltaE(colorTools.hex2rgb(c.hex), colorTools.hex2rgb(fill)))
    const color = colors[distances.indexOf(Math.min(...distances))]
    return color.hex
  },
  /**
   * Find array of fills from current colors which match best to given array of fills
   */
  matchingFills: (state, getters) => (fills, colors) => {
    return fills.map(fill => {
      return getters.matchingFill(fill, colors)
    })
  },
  /**
  * Check if selected object is colorizable
  */
  isColorizable: (state, getters, rootState, rootGetters) => {
    if (rootGetters.selectedObject) {
      if (rootGetters.selectedObject.type === 'text') {
        return true
      } else if (rootGetters.selectedObject.type === 'user-logo') {
        return false
      } else if (rootGetters.selectedObject.type === 'logo') {
        return rootGetters.selectedObject.meta.logo.colorize === 1
      } else {
        // @TODO: implement some kind of interface to allow colorizable custom objects
        return true
      }
    }
    return false
  },
  printtypePixelPriceScales: () => (printtype) => {
    if (!printtype.pixelPriceScale) return null

    return printtype.pixelPriceScale.split(';').map(scale => {
      const item = scale.split(',')
      return { size: item[0], price: item[1] }
    })
  },
  relevantPrinttypeObjects: (state, getters, rootState, rootGetters) => {
    let objectsToSet = []

    // Depending on printtypeMode set selected printtype
    // to current object (mode=object), all objects on view (mode=view), or all objects (mode=product)
    if (getters.printtypeMode === 'object') {
      rootGetters.selectedObject && objectsToSet.push(rootGetters.selectedObject)
    } else if (getters.printtypeMode === 'view') {
      objectsToSet = rootGetters.viewObjects(rootGetters.selectedVariantView)
    } else if (getters.printtypeMode === 'product') {
      objectsToSet = rootGetters.objects
    }

    return objectsToSet
  }
}

// actions
const actions = {
  setSelectedPrinttype ({ commit, dispatch, getters }, printtype) {
    const objectsToSet = getters.relevantPrinttypeObjects

    objectsToSet.forEach(o => {
      dispatch('setObjectMeta', {
        object: o,
        meta: Object.assign({}, o.meta, { printtype: printtype })
      })
      dispatch('initializeObjectPrinttype', o)
    })

    commit('setSelectedPrinttype', printtype)
  },
  /**
   * Make sure that when a new object forces certain printtypes
   * all other relevant objects switch to an allowed printtype
   * @TODO: This could also throw an error and either remove the new or the old objects
   */
  checkOtherObjectPrinttypes ({ commit, dispatch, getters, rootGetters, state }, object) {
    const objectsToSet = getters.relevantPrinttypeObjects

    objectsToSet.forEach(o => {
      if (o !== object) {
        const printtype = getters.initialObjectPrinttype({ object: o, reference: object })
        if (printtype.id !== o.meta.printtype.id) {
          dispatch('setObjectMeta', {
            object: o,
            meta: Object.assign({}, o.meta, { printtype: printtype })
          })
          dispatch('initializeObjectPrinttype', o)
        }
      }
    })
  },
  setPrinttypeMode ({ commit, dispatch, rootGetters }, mode) {
    commit('setPrinttypeMode', mode)
  }
}

// mutations
const mutations = {
  setSelectedPrinttype (state, printtype) {
    state.selected = printtype
  },
  setPrinttypeMode (state, mode) {
    state.printtypeMode = mode
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
