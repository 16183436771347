<template>
  <div>
    <div
      class="modal show"
      style="display:block"
    >
      <div class="modal-dialog modal-md  modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="d-flex flex-column justify-content-center my-3">
              <div class="mx-auto lds-ring">
                <div /><div /><div /><div />
              </div>
              <p class="text-center m-0">
                {{ $t('loading_data') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop" />
  </div>

  <!--
  <b-modal
    v-model="open"
    no-close-on-backdrop
    no-close-on-esc
    hide-header
    hide-footer
    centered
  >
    <div class="d-flex flex-column justify-content-center my-3">
      <b-spinner
        style="width: 3rem; height: 3rem;"
        class="mx-auto mb-3"/>
      <p class="text-center m-0">
        {{ $t('loading_data') }}
      </p>
    </div>
  </b-modal>
  -->
</template>

<script>

export default {
  name: 'LoadingScreen',
  data () {
    return {
      open: true
    }
  }
}
</script>

<style scoped>
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #333;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #333 transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
