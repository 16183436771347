import messages from '../../i18n/lang'

// initial state
const state = {
  fallback: 'de-DE',
  lang: 'de-DE',
  currency: 'EUR',
  translations: messages
}

// getters
const getters = {
  langugagePack: state => state.lang.split('-').shift(),
  fallbackLanguagePack: state => state.fallback.split('-').shift(),
  language: state => state.lang,
  translations: (state, getters) => state.translations[getters.langugagePack] ? state.translations[getters.langugagePack] : state.translations[getters.fallbackLanguagePack],
  translation: (state, getters) => key => getters.translations && getters.translations[key] ? getters.translations[key] : key,
  currency: state => state.currency
}

// actions
const actions = {
  setTranslation ({ commit }, { lang, translation }) {
    commit('setTranslation', { lang, translation })
  },
  setTranslationKey ({ commit }, { lang, translation, key }) {
    commit('setTranslationKey', { lang, translation, key })
  },
  setLanguage ({ commit }, language) {
    commit('setLanguage', language)
  },
  setCurrency ({ commit }, currency) {
    commit('setCurrency', currency)
  }
}

// mutations
const mutations = {
  setTranslation (state, { lang, translation }) {
    state.translations[lang] = Object.assign(state.translations[lang], translation)
  },
  setTranslationKey (state, { lang, translation, key }) {
    state.translations[lang][key] = translation
  },
  setLanguage (state, language) {
    state.lang = language
  },
  setCurrency (state, currency) {
    state.currency = currency
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
